export const MAP_CATEGORY_TO_G4 = {
  /* sedie: "chr_",
  sgabelli: "fts_",
  poltrone: "lch_",
  tavoli: "tbl_",
  madie_mobili: "sdb_",
  oggettistica: "ogg_",
  complementi: "cmp_",
  tappeti: "crp_", */
  sedie: "ch_",
  sgabelli: "ft_",
  poltrone: "lc_",
  tavoli: "tb_",
  madie_mobili: "sd_",
  oggettistica: "og_",
  complementi: "cm_",
  tappeti: "cr_",
  letti_configurable: "bd_",
  divani_configurable: "sf_"
}

export const MAP_PART_TO_G4 = {
  /* sedile: "set_",
  gambe_cassonetto: "blg_",
  piano_allunga: "flr_",
  basamento: "bsm_",
  frontale_cassetto: "frn_",
  anta_cassetto: "bdr_",
  top: "top_",
  struttura: "str_" */
  sedile: "st_",
  gambe_cassonetto: "bl_",
  piano_allunga: "fl_",
  basamento: "bs_",
  frontale_cassetto: "fr_",
  anta_cassetto: "bd_",
  top: "tp_",
  struttura: "sr_"
}