let categories = {

    'configured': [
        { key: "all", value: "All" },
        { key: "sedie", value: "sedie" },
        { key: "sgabelli", value: "sgabelli" },
        { key: "poltrone", value: "poltrone" },
        { key: "tavoli", value: "tavoli" },
        { key: "madie_mobili", value: "madie_mobili" },
        { key: "oggettistica", value: "oggettistica" },
        { key: "complementi", value: "complementi" },
        { key: "tappeti", value: "tappeti" },
        // { key: "letti", value: "letti" },
        // { key: "divani", value: "divani" },
    ],
    'outlet': [
        { key: "all", value: "All" },
        { key: "sedie", value: "sedie" },
        { key: "sgabelli", value: "sgabelli" },
        { key: "poltrone", value: "poltrone" },
        { key: "tavoli", value: "tavoli" },
        { key: "madie_mobili", value: "madie_mobili" },
        { key: "oggettistica", value: "oggettistica" },
        { key: "complementi", value: "complementi" },
        { key: "tappeti", value: "tappeti" },
        // { key: "letti", value: "letti" },
        // { key: "divani", value: "divani" },
    ],

    'configurable': [
        { key: "all", value: "All" },
        { key: "letti", value: "letti" },
        { key: "divani", value: "divani" },
    ],
}

export default categories;