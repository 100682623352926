import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { checkResetPwdToken, sendResetPwd } from "../../actions/user.js";

import { ReactComponent as PictogramSVG } from "./../../img/icons/pictogram.svg";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import useDocumentTitle from "../../helpers/useDocumentTitle.js";


function ResetPwd() {
  console.log("ResetPwd");
  const { t } = useTranslation();
  useDocumentTitle(t("Reset password"))
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const token = params.token;
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);

  const [details, setDetails] = useState({ password: "", confirmPassword: "" });
  const [errors, setErrors] = useState({ password: "", confirmPassword: "" });
  const history = useHistory();
  const checkResetToken = async () => {
    try {
      setError("");
      setLoading(true);
      const response = await checkResetPwdToken(token);
      if (response) {
        setLoading(false);
        console.log({ response });
        return;
      }
    } catch (err) {
      console.log(err);
    }
    setError("Token non valido");
    setLoading(false);
  };

  React.useEffect(checkResetToken, []);

  const validate = function () {
    let errors = {
      password: "",
      confirmPassword: "",
    };
    let isValid = true;

    if (!details.password) {
      isValid = false;
      errors.password = "Please enter your new password.";
    }

    var pattern = new RegExp(
      /(((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|])(?=.*[a-z]).*$)/
    );
    if (!details.password || !pattern.test(details.password)) {
      isValid = false;
      errors.password =
        "Password must contains at least one letter, one UpperCase letter, one number and one special character.";
    }
    if (
      !details.password ||
      details.password.length < 8 ||
      details.password.length > 20
    ) {
      isValid = false;
      errors.password =
        "Password must be longer than 8 characters and lower than 20 characters.";
    }
    if (!details.confirmPassword) {
      isValid = false;
      errors.confirmPassword = "Please enter your confirm password.";
    }

    if (
      typeof details.password !== "undefined" &&
      typeof details.confirmPassword !== "undefined"
    ) {
      if (details.password !== details.confirmPassword) {
        isValid = false;
        errors.password = "Passwords don't match.";
      }
    }

    setErrors(errors);
    return isValid;
  };

  const goToLogin = function () {
    history.push("/login");
  };

  const resetPassword = async () => {
    setError("");
    setLoading(true);
    const isValid = validate();
    if (!isValid) {
      setLoading(false);
      return;
    }
    const response = await sendResetPwd(
      token,
      details.password,
      details.confirmPassword
    );
    setLoading(false);
    setPasswordReset(response);
    console.log({ response });

    if (!response) {
      errors.password = "The service is not available at the moment. Please, try again later.";
      setErrors(errors);
    } else {
      /* window.gtag('event', 'reset_password_process_complete', {
        'event_callback': () => {
          console.log("reset password process complete event sent to google analytics")
        }
      }); */
      ReactGA.event('reset_password_process_complete')
    }
  };

  return (
    <>
      <div
        className="page-background"
        style={{
          backgroundImage:
            "url(https://www.calligaris.com/media/immagini/1964_n_cs3434_S9F_cs3427_SQ5_cs5098-G-P_P175_P2C_cs6096-3B_cs7207-.jpg)",
        }}
      ></div>
      <div className="header transparent">
        <div className="container">
          <div className="header-wrapper">
            <div className="logo">calligaris</div>
            <div className="pictogram">
              <PictogramSVG />
            </div>
          </div>
        </div>
      </div>

      <div className="main reset-pwd">
        <div className="container">
          <div className="form-card">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                resetPassword();
              }}
            >
              {passwordReset == "" && error == "" && (
                <>
                  <h1 className="form-title">{t("Reset password")}</h1>

                  <div className="form-row">
                    <label htmlFor="password">{t("Password")}</label>
                    <input
                      type="password"
                      name="password"
                      placeholder={t("Password")}
                      id="password"
                      onChange={(e) =>
                        setDetails({ ...details, password: e.target.value })
                      }
                      value={details.password}
                    />
                  </div>

                  <div className="form-row">
                    <label htmlFor="confirmPassword">{t("Confirm Password")}</label>
                    <input
                      type="password"
                      name="confirmPassword"
                      placeholder={t("Confirm Password")}
                      id="confirmPassword"
                      onChange={(e) =>
                        setDetails({
                          ...details,
                          confirmPassword: e.target.value,
                        })
                      }
                      value={details.confirmPassword}
                    />
                  </div>

                  <div className="form-actions">
                    <a href="mailto:#">{t("Need support?")}</a>

                    <button className="button" type="submit" disabled={loading}>
                      {t("Change Password")}
                    </button>
                  </div>
                </>
              )}

              {passwordReset != "" && (
                <>
                  <h1 className="form-title">{t("password_reset_title")}</h1>
                  <p className="form-description">{t("password_reset_body")}</p>

                  <div className="form-actions">
                    <a href="mailto:#">{t("Need support?")}</a>

                    <button
                      className="button"
                      type="submit"
                      onClick={goToLogin}
                    >
                      {t("Go to login")}
                    </button>
                  </div>
                </>
              )}

              {errors.password !== "" && (
                <div className="form-alert danger">{t(errors.password)}</div>
              )}

              {errors.password === "" && errors.confirmPassword !== "" && (
                <div className="form-alert danger">
                  {t(errors.confirmPassword)}
                </div>
              )}

              {error != "" && (
                <div className="form-alert danger">{t(error)}</div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPwd;
