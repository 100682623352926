import React from "react";
import { useCart } from "react-use-cart";
import { Table, Button, InputGroup, FormControl, Modal } from "react-bootstrap";
import ApiService from "../../actions/apiService";
import * as PropTypes from "prop-types";
import { XSquareFill } from "react-bootstrap-icons";
import PriceUtils from "../../helpers/price";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { retrieveMTOImages } from "../../helpers/imgRetrievers";
import placeholder from "../../img/placeholder.jpg"
function NumericInput(props) {
  return null;
}

NumericInput.propTypes = {
  style: PropTypes.bool,
  className: PropTypes.string,
};

const CartPopover = (opt) => {
  const {
    isEmpty,
    items,
    cartTotal,
    updateItemQuantity,
    removeItem,
    emptyCart,
    clearCartMetadata,
    metadata,
  } = useCart();
  let ref = React.useRef(null);
  const history = useHistory();
  const { t } = useTranslation();
  const currency = items[0]?.currency;

  const changePath = (location) => {
    history.push(location);
  };
  const goToCart = () => {
    changePath("/cart");
    document.body.click();
  };

  if (isEmpty)
    return <p className="empty-cart-popover">{t("quotation is empty")}</p>;

  return (
    <>
      <div className="products">
        <table>
          <tbody>
            {items.map((item, index) => {
              return (
                <tr key={index}>
                  <td className="image">
                  { item.isD3 || item.product.procurementMethod !== 'MTO' 
                      ? <img src={item.img} alt="" onError={(currentTarget) => {
                        currentTarget.target.src = placeholder; }}/> 
                      : retrieveMTOImages(item)}
                  </td>
                  <td className="details">
                    <div className="title">{item.title}</div>
                    <div className="price">
                      {PriceUtils.getPrice(item.price, item.currency)}
                    </div>
                    <div className="quantity">
                      {t("quantity")}: {item.quantity}
                    </div>
                    <div className="delete">
                      <a onClick={() => removeItem(item.id)}>{t("Delete")}</a>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="subtotal">
        <div className="label">{t("Subtotal")}:</div>
        <div className="price">{PriceUtils.getPrice(cartTotal, currency)}</div>
      </div>

      <div className="actions">
        <button className="button" onClick={() => goToCart()}>
          {t("Go to quotation")}
        </button>
      </div>
    </>
  );
};

export default CartPopover;
