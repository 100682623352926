import { useState, useEffect } from "react"
import { NavDropdown } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useCart } from "react-use-cart"
import ApiService from "../../actions/apiService"
import { CounterBox } from "../../components/CounterBox/CounterBox"
import { retrieveMTOImages, retrieveSofaIconSrc } from "../../helpers/imgRetrievers"
import { ReactComponent as SearchSVG } from "../../img/icons/search.svg";
import PriceUtils from "../../helpers/price"

//cose tmp
import placeholder from "./../../img/placeholder.jpg"
import { ProductTable } from "../../components/ProductTable/ProductTable"


export const Dashboard = () => {

  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [currencies, setCurrency] = useState([]);
  const [quotations, setQuotations] = useState({ total: { numberQuotations: 0, numberQuotationsDownloaded: 0, numberQuotationsEdited: 0 }, active: { numberQuotations: 0, numberQuotationsDownloaded: 0, numberQuotationsEdited: 0 }, expired: { numberQuotations: 0, numberQuotationsDownloaded: 0, numberQuotationsEdited: 0 } })
  const [ordersCurrency, setCurrentOrdersCurrency] = useState("EUR");
  const [discountsCurrency, setCurrentDiscountsCurrency] = useState("EUR");
  const [transportsCurrency, setCurrentTransportsCurrency] = useState("EUR");
  const [orders, setOrders] = useState({ total: {}, active: {}, expired: {} })
  const [discounts, setDiscounts] = useState({ total: {}, active: {}, expired: {} })
  const [transports, setTransports] = useState({ total: {}, active: {}, expired: {} })
  const [topProducts, setTopProducts] = useState([])
  const [topCategories, setTopCategories] = useState([])
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  //Inizio cose tmp per test tabella

  const getProductDescription = function (product) {
    let description = product.parentVersion + " ";
    if (product.productDescription3 && typeof (product.productDescription3) != "undefined") {
      description += product.productDescription3 + " " + product.productDescription2;
    } else if (product.description && typeof (product.description) != "undefined") {
      description += product.description;
    } else if (product.productDescription2 && typeof (product.productDescription2) != "undefined") {
      description += product.productDescription2;
    }
    return description;
  }

  //////////////////////////////////////////////////////

  const getAnalytics = async (username) => {

    const query = {}
    if (username.trim().length > 0)
      query["username"] = username

    try {
      setError(false)
      setLoading(true)
      const res = await ApiService.get("/analytics", query)
      const totalData = res.analyticsQuotations.at(res.analyticsQuotations.findIndex(element => element.type === "TOTAL")).result
      const activeData = res.analyticsQuotations.at(res.analyticsQuotations.findIndex(element => element.type === "ACTIVE")).result
      const expiredData = res.analyticsQuotations.at(res.analyticsQuotations.findIndex(element => element.type === "EXPIRED")).result

      const totalQuotations = { numberQuotations: totalData.numberQuotations, numberQuotationsDownloaded: totalData.numberQuotationsDownloaded, numberQuotationsEdited: totalData.numberQuotationsEdited }
      const activeQuotations = { numberQuotations: activeData.numberQuotations, numberQuotationsDownloaded: activeData.numberQuotationsDownloaded, numberQuotationsEdited: activeData.numberQuotationsEdited }
      const expiredQuotations = { numberQuotations: expiredData.numberQuotations, numberQuotationsDownloaded: expiredData.numberQuotationsDownloaded, numberQuotationsEdited: expiredData.numberQuotationsEdited }

      const dataOrders = { total: {}, active: {}, expired: {} }
      const dataDiscounts = { total: {}, active: {}, expired: {} }
      const dataTransports = { total: {}, active: {}, expired: {} }
      const dataCurrencies = []

      totalData.averages.forEach(averages => {
        dataCurrencies.push(averages.currency)
        dataOrders.total[averages.currency] = averages.avgAmount
        dataDiscounts.total[averages.currency] = averages.avgDiscount
        dataTransports.total[averages.currency] = averages.avgTransportationFee
      })

      activeData.averages.forEach(averages => {
        dataOrders.active[averages.currency] = averages.avgAmount
        dataDiscounts.active[averages.currency] = averages.avgDiscount
        dataTransports.active[averages.currency] = averages.avgTransportationFee
      })

      expiredData.averages.forEach(averages => {
        dataOrders.expired[averages.currency] = averages.avgAmount
        dataDiscounts.expired[averages.currency] = averages.avgDiscount
        dataTransports.expired[averages.currency] = averages.avgTransportationFee
      })

      const quotationObj = { total: totalQuotations, active: activeQuotations, expired: expiredQuotations }
      setCurrency(dataCurrencies)
      setCurrentOrdersCurrency(dataCurrencies[0])
      setCurrentDiscountsCurrency(dataCurrencies[0])
      setCurrentTransportsCurrency(dataCurrencies[0])
      setQuotations(quotationObj)
      setOrders(dataOrders)
      setDiscounts(dataDiscounts)
      setTransports(dataTransports)
      setTopProducts(res.top30Products)
      setTopCategories(res.top30Categories)
      setLoading(false)
    } catch (err) {
      err
        .then(data => {
          if (data.statusCode === 400 && data.message === "param not valid!") {
            setError(true)
            setLoading(false)
          }
        })
        .catch(data => {
          setLoading(false);
          setError(true)
        })
    }
  }

  useEffect(async () => { getAnalytics(username) }, [])

  return <div className="container main">
    <div className="dashboard-search-form">
      <input
        type="text"
        placeholder={t("Search customer...")}
        onChange={(e) => setUsername(e.target.value)}
      />
      <button onClick={(e) => getAnalytics(username)} className="only-icon">
        <span className="label">{t("Search")}</span>
        <SearchSVG />
      </button>
    </div>
    {error && <h2><b>{t("The search did not produce any results")}</b></h2>}
    {loading && <span>{t("Loading")}...</span>}
    {!loading && !error && <>
      <div className="dashboard-section">
        <h3>{t("Quotation")}:</h3>
        <div className="d-flex justify-content-evenly gap-3 flex-wrap">
          <CounterBox title={t("Total")} total={quotations.total.numberQuotations} />
          <CounterBox title={t("Quotations sent")} total={quotations.total.numberQuotationsDownloaded} />
          <CounterBox title={t("Quotations updated")} total={quotations.total.numberQuotationsEdited} />
        </div>
      </div>
      <div className="dashboard-section">
        <h3>{t("Active quotations")}:</h3>
        <div className="d-flex justify-content-evenly gap-3 flex-wrap">
          <CounterBox title={t("Total")} total={quotations.active.numberQuotations ?? 0} />
          <CounterBox title={t("Quotations sent")} total={quotations.active.numberQuotationsDownloaded ?? 0} />
          <CounterBox title={t("Quotations updated")} total={quotations.active.numberQuotationsEdited ?? 0} />
        </div>
      </div>
      <div className="dashboard-section">
        <h3>{t("Expired quotations")}:</h3>
        <div className="d-flex justify-content-evenly gap-3 flex-wrap">
          <CounterBox title={t("Total")} total={quotations.expired.numberQuotations ?? 0} />
          <CounterBox title={t("Quotations sent")} total={quotations.expired.numberQuotationsDownloaded ?? 0} />
          <CounterBox title={t("Quotations updated")} total={quotations.expired.numberQuotationsEdited ?? 0} />
        </div>
      </div>
      { orders.length > 0 &&
      <div className="dashboard-section">
        <div className="d-flex gap-3 justify-content-between align-items-baseline">
          <h3 style={{ marginRight: "auto" }}>{t("Average orders")}:</h3>
          <h4 style={{ fontWeight: "bold" }}> {t("Current currency")}:</h4>
          <NavDropdown className="dashboard-dropdown" title={ordersCurrency}
            onSelect={(cur) => { setCurrentOrdersCurrency(cur) }}>
            {currencies.map((cur) =>
              <NavDropdown.Item eventKey={cur} active={ordersCurrency === cur} key={"cur " + cur}>
                {cur}
              </NavDropdown.Item>
            )}
          </NavDropdown>
        </div>

        <div className="d-flex justify-content-evenly gap-3 flex-wrap">
          <CounterBox title={t("Total orders")} decimals={2}
            totalWithCurrency={PriceUtils.getPrice(orders.total[ordersCurrency], ordersCurrency) ?? "0€"} />
          <CounterBox title={t("Active orders")} decimals={2}
            totalWithCurrency={PriceUtils.getPrice(orders.active[ordersCurrency], ordersCurrency) ?? "0€"} />
          <CounterBox title={t("Expired orders")} decimals={2}
            totalWithCurrency={PriceUtils.getPrice(orders.expired[ordersCurrency], ordersCurrency) ?? "0€"} />
        </div>
      </div>
      }
      { discounts.length > 0 &&
      <div className="dashboard-section">
        <div className="d-flex gap-3 justify-content-between align-items-baseline">
          <h3 style={{ marginRight: "auto" }}>{t("Average discounts")}</h3>
          <h4 style={{ fontWeight: "bold" }}> {t("Current currency")}</h4>
          <NavDropdown className="dashboard-dropdown" title={discountsCurrency}
            onSelect={(cur) => { setCurrentDiscountsCurrency(cur) }}>
            {currencies.map((cur) =>
              <NavDropdown.Item eventKey={cur} active={discountsCurrency === cur} key={"cur " + cur}>
                {cur}
              </NavDropdown.Item>
            )}
          </NavDropdown>
        </div>
        <div className="d-flex justify-content-evenly gap-3 flex-wrap">
          <CounterBox title={t("Total discounts")} decimals={2}
                      total={""+discounts.total[discountsCurrency] ?? "0"}  suffix={"%"} />
          <CounterBox title={t("Active discounts")} decimals={2}
                      total={""+discounts.active[discountsCurrency] ?? "0"} suffix={"%"} />
          <CounterBox title={t("Expired discounts")} decimals={2}
                      total={""+discounts.expired[discountsCurrency] ?? "0"}  suffix={"%"} />
        </div>
      </div>
      }
      { transports.length > 0 &&
      <div className="dashboard-section">
        <div className="d-flex gap-3 justify-content-between align-items-baseline">
          <h3 style={{ marginRight: "auto" }}>{t("Transport and assembly fee")}:</h3>
          <h4 style={{ fontWeight: "bold" }}>{t("Current currency")}:</h4>
          <NavDropdown className="dashboard-dropdown" title={transportsCurrency}
            onSelect={(cur) => { setCurrentTransportsCurrency(cur) }}>
            {currencies.map((cur) =>
              <NavDropdown.Item eventKey={cur} active={transportsCurrency === cur} key={"cur " + cur}>
                {cur}
              </NavDropdown.Item>
            )}
          </NavDropdown>
        </div>
        <div className="d-flex justify-content-evenly gap-3 flex-wrap">
          <CounterBox title={t("Total Transport and assembly fee")} decimals={2}
            totalWithCurrency={PriceUtils.getPrice(transports.total[transportsCurrency], transportsCurrency) ?? "0€"} />
          <CounterBox title={t("Active Transport and assembly fee")} decimals={2}
            totalWithCurrency={PriceUtils.getPrice(transports.active[transportsCurrency], transportsCurrency) ?? "0€"} />
          <CounterBox title={t("Expired Transport and assembly fee")} decimals={2}
            totalWithCurrency={PriceUtils.getPrice(transports.expired[transportsCurrency], transportsCurrency) ?? "0€"} />
        </div>
      </div>
      }
      <div className="dashboard-section dashboard-product">
        <h3>Top 30 {t("products")}:</h3>
        <div className="products">
          <ProductTable items={topProducts} />
        </div>
      </div>

      <div className="dashboard-section">
        <h3>Top {t("categories")}:</h3>
        <div class="border border-top-0">
          {topCategories.map((item, index) => (
            <div key={index} className="category-info">
              <p className="title text-capitalize"><b>{t(item.category.toLowerCase())}</b></p>
              <p className="quantity">{t("Quantity")}: {item.quantity}</p>
            </div>
          ))}
        </div>
      </div></>}
  </div >

}