const getProductDescription = (productDescription2, productDescription3, productDescription, parentVersion) => {
  let description = parentVersion + " ";
  if (productDescription3 && typeof (productDescription3) != "undefined") {
    description += productDescription3 + " " + productDescription2;
  } else if (productDescription && typeof (productDescription) != "undefined") {
    description += description;
  } else if (productDescription2 && typeof (productDescription2) != "undefined") {
    description += productDescription2;
  }
  return description;
}

export const ProductTableDetails = ({ productDescription2, productDescription3, productDescription, title = "", options = "", parentVersion = "" }) => {
  return <div className="details">
    <div className="title">
      {title}
    </div>
    <div className="description">
      {getProductDescription(productDescription2, productDescription3, productDescription, parentVersion)}
    </div>
    <div className="options">
      {options &&
        Object.keys(options).map((key) => {
          return (
            <span>
              {key}: {options[key]}
            </span>
          );
        })}
    </div>
  </div>
}