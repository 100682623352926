import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import reducers from './reducers/index.js';
export const history = createBrowserHistory({ basename: '/' });



const logger = createLogger({ collapsed: (getState, action, logEntry) => !logEntry.error });
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
    combineReducers({
        ...reducers,
        router: connectRouter(history)
    }),
    composeEnhancer(
        applyMiddleware(
            routerMiddleware(history),
            logger
        )
    )
);
