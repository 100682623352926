import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom'
import React from 'react';

class AuthRoute extends React.Component {

    /* componentDidUpdate() {
        console.log(this.props)
        /* if (this.props.pageName)
            window.title = this.props.pageName
     }*/ 

    render() {
        console.log('AuthRoute', this.props.path, this.props);
        let Component = this.props.component;
        if (!this.props.loaded)
            return null;
        return (
            <Route exact path={this.props.path} render={props => (
                this.props.isLoggedIn ?
                    <Component {...props} productType={this.props.productType} productGroup={this.props.productGroup} />
                    :
                    <Redirect push to="/login" />
            )} />
        );
    }
}

export default withRouter(connect(state => {
    return {
        isLoggedIn: state.user.accessToken,
        loaded: state.user.loaded
    };
})(AuthRoute));

