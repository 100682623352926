import React, {useState} from "react";
import {NavDropdown, OverlayTrigger, Popover} from "react-bootstrap";
import {logout} from "../actions/user";
import {useCart} from "react-use-cart";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import CartPopover from "./cart/CartPopover";

import {ReactComponent as ArrowSVG} from "./../img/icons/arrow.svg";
import {ReactComponent as LogoutSVG} from "./../img/icons/logout.svg";
import {ReactComponent as SearchSVG} from "./../img/icons/search.svg";
import categories from "../helpers/categories";
import {generalConditionsOfSale, trimPDFPaths, userManual} from "../helpers/documents";
import ReactGA from "react-ga4";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { sanitizeNavigatorLanguage } from "../helpers/language";


export default function NavbarComponent(props) {
  const [search, setSearch] = React.useState("");

  const { i18n, t } = useTranslation();
  let lngFromLocalStorage = (localStorage.getItem("language") && localStorage.getItem("language")!== 'null') ? localStorage.getItem("language") : null;
  if(!lngFromLocalStorage){
    var lng = navigator.language;
    lng = sanitizeNavigatorLanguage(lng);
    lngFromLocalStorage = lng;
  }
  if(lngFromLocalStorage && lngFromLocalStorage === "it"){
    lngFromLocalStorage = "IT_IT";
  } else if(lngFromLocalStorage && lngFromLocalStorage === "en"){
    lngFromLocalStorage = "EN_US";
  }
  const [user, setUser] = React.useState(
    props.user
  );
  const [language, setLanguage] = React.useState(
    lngFromLocalStorage || "EN_US"
  );
  let sizeUnitLocalStorage = localStorage.getItem("sizeUnit") ;

  const [sizeUnit, setSizeUnit] = React.useState(
      sizeUnitLocalStorage || "decimal"
  );

  let onlyProductAvailableStorage = localStorage.getItem("onlyProductAvailable") === "true" || false;

  //if not set set as true
  if (localStorage.getItem("onlyProductAvailable") === null || localStorage.getItem("onlyProductAvailable") === "null"){
    onlyProductAvailableStorage = true;
  }
  const [onlyProductAvailable, setOnlyProductAvailable] = React.useState(
      onlyProductAvailableStorage
  );

  function changeSizeUnit(){
    //size unit
    let sizeUnitLocalStorage = sizeUnit || localStorage.getItem("sizeUnit") ;
    if(!sizeUnitLocalStorage && user.priceListCodes.includes(5022)){
      sizeUnitLocalStorage = "imperial";
    } else if(!sizeUnitLocalStorage && !user.priceListCodes.includes(5022)){
      sizeUnitLocalStorage = "decimal";
    }
    localStorage.setItem("sizeUnit", sizeUnitLocalStorage || "decimal");

  }

  changeSizeUnit();


  React.useEffect(async () => {
    await changeSizeUnit();
  }, [sizeUnit]);

  function changeOnlyProductAvailable(){
    //onlyProductAvailable
    localStorage.setItem("onlyProductAvailable", onlyProductAvailable || false);
  }

  changeOnlyProductAvailable();
  React.useEffect(async () => {
    await changeOnlyProductAvailable();
  }, [onlyProductAvailable]);



  const languages = [
    { key: "IT_IT", label: "it" },
    { key: "EN_US", label: "en_US" },
    { key: "EN_GB", label: "en_GB" },
    { key: "FR_FR", label: "fr" },
    { key: "DE_DE", label: "de" },
    { key: "ES_ES", label: "es" }
  ];
  const history = useHistory();
  const location = useLocation();
  console.log("location", location);
  const productType =
    location.pathname.includes("configurable") ||
      location.pathname.includes("ditre")
      ? "configurable"
      : "configured";
  const searchParams = location.search; // could be '?foo=bar'
  const params = new URLSearchParams(searchParams);
  const selectedCategory = params.get("category") || "all";

  const [category, setCategory] = React.useState(selectedCategory);

  const selectedPath = location.pathname.split("/")[1];
  const getActiveClass = (path, category) => {
    if (category === null && selectedPath === path) {
      return "active";
    }

    return selectedPath === path && selectedCategory === category
      ? "active"
      : "";
  };
  const { totalItems } = useCart();

  const changePath = (location) => {
    history.push(location);
  };
  const logoutActon = () => {
    logout().then(() => {
      ReactGA.set({ userId: null })
    });
    changePath("/login");
  };
  const changeLanguage = (lng) => {
    console.log('changeLanguage ' + lng)
    setLanguage(lng);
    i18n.changeLanguage(lng);

    localStorage.setItem("language", lng ||"it_it");
  };


  const [currentVisible, setCurrentVisible] = useState(null);
  const getCurrentVisibleClass = (el) => {
    if (currentVisible === null) return "";

    return el === currentVisible ? "visible" : "hidden";
  };

  const goToSearch = function () {
    changePath(`/${productType}/search?search=${search}&category=${category}`);
    ReactGA.event(`search_${search}`)
  };

  React.useEffect(() => {
    changeLanguage(language);
  }, [language]);

  React.useEffect(() => {
    changeSizeUnit(sizeUnit);
  }, [sizeUnit]);

  const cartPopover = (
    <Popover className="popover cart-popover">
      <Popover.Body>
        <CartPopover />
      </Popover.Body>
    </Popover>
  );

  const searchPopover = (
    <Popover className="popover search-popover">
      <Popover.Body>
        <div className="container">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              goToSearch();
            }}
          >
            <div className="search-input">
              <input
                type="text"
                placeholder={t("Search product") + "..."}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button className="only-icon">
                <span className="label">{t("Search")}</span>
                <SearchSVG />
              </button>
            </div>

            <div className="category-input">
              <NavDropdown
                id="category-button"
                title={t(category)}
                onSelect={(cat) => {
                  console.log("select cat", cat);
                  setCategory(cat);
                }}
              >
                {categories[productType].map((cat) => {
                  return (
                    <NavDropdown.Item
                      eventKey={cat.key}
                      active={category === cat.key}
                      key={cat.key}
                    >
                      {t(cat.value)}
                    </NavDropdown.Item>
                  );
                })}
              </NavDropdown>
            </div>
          </form>
        </div>
      </Popover.Body>
    </Popover>
  );

  const [offCanvasOpen, setOffCanvasOpen] = React.useState(false);

  /* Hide/show nav bar on scroll */
  /*const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      let y = window.pageYOffset;

      if (position > y) {
        setVisible(true);
      } else {
        setVisible(false);
      }

      setPosition(y);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });*/

  const sendEventQuotations = () => {
    /* window.gtag('event', `history_quotations_retrieved`, {
      'event_callback': () => {
        console.log(`history quotations retrieved event sent to google analytics`)
      }
    }); */
    ReactGA.event(`history_quotations_retrieved`)
  }

  return (
    <>
      <div className={"header" /*`header ${!visible ? "hidden" : ""}`*/}>
        <div className="container">
          <div className="header-wrapper">
            <div className="left">
              <button className="burger" onClick={() => setOffCanvasOpen(true)}>
                <span></span>
              </button>

              <Link className="logo" to="/">
                Calligaris
              </Link>
            </div>

            <div className="right">
              <div className="menu">
                {location.pathname.match(/configured|configurable|product/) && (
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={searchPopover}
                    container={this}
                    rootClose={true}
                  >
                    <button className="cart">{t("Search")}</button>
                  </OverlayTrigger>
                )}

                {/*<OverlayTrigger*/}
                {/*  trigger="click"*/}
                {/*  placement="bottom"*/}
                {/*  overlay={cartPopover}*/}
                {/*  container={this}*/}
                {/*  rootClose={true}*/}
                {/*>*/}
                {/*  <button className="cart">*/}
                {/*    <span className="label">{t("Quotation")}</span>*/}
                {/*    <span className="counter">{totalItems}</span>*/}
                {/*  </button>*/}
                {/*</OverlayTrigger>*/}

                {/*<Link to="/quotations" className="history" onClick={() => sendEventQuotations()}>*/}
                {/*  {t("History")}*/}
                {/*</Link>*/}

                <button className="logout" onClick={() => logoutActon()}>
                  <span className="label">{t("Logout")}</span>
                  <LogoutSVG />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dummy-header"></div>

      <div className={`off-canvas ${offCanvasOpen ? "open" : ""}`}>
        <div className="top">
          <button
            className="close"
            onClick={() => setOffCanvasOpen(false)}
          ></button>

          <span className="brand">Calligaris</span>
        </div>

        <div className="menu">
          <ul className="main-menu">
            {/*<li className={getCurrentVisibleClass("configured")}>*/}
            {/*  <span>*/}
            {/*    <button*/}
            {/*      className="prev"*/}
            {/*      onClick={() => setCurrentVisible(null)}*/}
            {/*    >*/}
            {/*      <ArrowSVG />*/}
            {/*    </button>*/}
            {/*    <a onClick={(e) => changePath("/configured")}>*/}
            {/*      {t("Configured")}*/}
            {/*    </a>*/}
            {/*    <button*/}
            {/*      className="next"*/}
            {/*      onClick={() => setCurrentVisible("configured")}*/}
            {/*    >*/}
            {/*      <ArrowSVG />*/}
            {/*    </button>*/}
            {/*  </span>*/}

            {/*  <ul>*/}
            {/*    <li>*/}
            {/*      <a*/}
            {/*        onClick={(e) =>*/}
            {/*          changePath("/configured/search?category=sedie")*/}
            {/*        }*/}
            {/*        className={getActiveClass("configured", "sedie")}*/}
            {/*      >*/}
            {/*        {t("sedie")}*/}
            {/*      </a>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*      <a*/}
            {/*        onClick={(e) =>*/}
            {/*          changePath("/configured/search?category=sgabelli")*/}
            {/*        }*/}
            {/*        className={getActiveClass("configured", "sgabelli")}*/}
            {/*      >*/}
            {/*        {t("sgabelli")}*/}
            {/*      </a>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*      <a*/}
            {/*        onClick={(e) =>*/}
            {/*          changePath("/configured/search?category=poltrone")*/}
            {/*        }*/}
            {/*        className={getActiveClass("configured", "poltrone")}*/}
            {/*      >*/}
            {/*        {t("poltrone")}*/}
            {/*      </a>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*      <a*/}
            {/*        onClick={(e) =>*/}
            {/*          changePath("/configured/search?category=tavoli")*/}
            {/*        }*/}
            {/*        className={getActiveClass("configured", "tavoli")}*/}
            {/*      >*/}
            {/*        {t("tavoli")}*/}
            {/*      </a>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*      <a*/}
            {/*        onClick={(e) =>*/}
            {/*          changePath("/configured/search?category=madie_mobili")*/}
            {/*        }*/}
            {/*        className={getActiveClass("configured", "madie_mobili")}*/}
            {/*      >*/}
            {/*        {t("madie")}*/}
            {/*      </a>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*      <a*/}
            {/*        onClick={(e) =>*/}
            {/*          changePath("/configured/search?category=madie_mobili")*/}
            {/*        }*/}
            {/*        className={getActiveClass("configured", "madie_mobili")}*/}
            {/*      >*/}
            {/*        {t("mobili")}*/}
            {/*      </a>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*      <a*/}
            {/*        onClick={(e) =>*/}
            {/*          changePath("/configured/search?category=oggettistica")*/}
            {/*        }*/}
            {/*        className={getActiveClass("configured", "oggettistica")}*/}
            {/*      >*/}
            {/*        {t("oggettistica")}*/}
            {/*      </a>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*      <a*/}
            {/*        onClick={(e) =>*/}
            {/*          changePath("/configured/search?category=complementi")*/}
            {/*        }*/}
            {/*        className={getActiveClass("configured", "complementi")}*/}
            {/*      >*/}
            {/*        {t("complementi")}*/}
            {/*      </a>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*      <a*/}
            {/*        onClick={(e) =>*/}
            {/*          changePath("/configured/search?category=tappeti")*/}
            {/*        }*/}
            {/*        className={getActiveClass("configured", "tappeti")}*/}
            {/*      >*/}
            {/*        {t("tappeti")}*/}
            {/*      </a>*/}
            {/*    </li>*/}
            {/*  </ul>*/}
            {/*</li>*/}
            {/*<li className={getCurrentVisibleClass("configurable")}>*/}
            {/*  <span>*/}
            {/*    <button*/}
            {/*      className="prev"*/}
            {/*      onClick={() => setCurrentVisible(null)}*/}
            {/*    >*/}
            {/*      <ArrowSVG />*/}
            {/*    </button>*/}
            {/*    <a onClick={(e) => changePath("/configurable")}>*/}
            {/*      {t("Configurable")}*/}
            {/*    </a>*/}
            {/*    <button*/}
            {/*      className="next"*/}
            {/*      onClick={() => setCurrentVisible("configurable")}*/}
            {/*    >*/}
            {/*      <ArrowSVG />*/}
            {/*    </button>*/}
            {/*  </span>*/}

            {/*  <ul>*/}
            {/*    <li>*/}
            {/*      <a*/}
            {/*        onClick={(e) =>*/}
            {/*          changePath("/configurable/search?category=letti")*/}
            {/*        }*/}
            {/*        className={getActiveClass("configured", "letti")}*/}
            {/*      >*/}
            {/*        {t("letti")}*/}
            {/*      </a>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*      <a*/}
            {/*        onClick={(e) =>*/}
            {/*          changePath("/configurable/search?category=divani")*/}
            {/*        }*/}
            {/*        className={getActiveClass("configured", "divani")}*/}
            {/*      >*/}
            {/*        {t("divani")}*/}
            {/*      </a>*/}
            {/*    </li>*/}
            {/*  </ul>*/}
            {/*</li>*/}
            <li className={getCurrentVisibleClass("outlet")}>
              <span>
                <button
                    className="prev"
                    onClick={() => setCurrentVisible(null)}
                >
                  <ArrowSVG />
                </button>
                <a onClick={(e) => changePath("/outlet")}>
                  {t("Outlet")}
                </a>
                <button
                    className="next"
                    onClick={() => setCurrentVisible("outlet")}
                >
                  <ArrowSVG />
                </button>
              </span>

              <ul>
                <li>
                  <a
                      onClick={(e) =>
                          changePath("/outlet/search?category=sedie")
                      }
                      className={getActiveClass("outlet", "sedie")}
                  >
                    {t("sedie")}
                  </a>
                </li>
                <li>
                  <a
                      onClick={(e) =>
                          changePath("/outlet/search?category=sgabelli")
                      }
                      className={getActiveClass("outlet", "sgabelli")}
                  >
                    {t("sgabelli")}
                  </a>
                </li>
                <li>
                  <a
                      onClick={(e) =>
                          changePath("/outlet/search?category=poltrone")
                      }
                      className={getActiveClass("outlet", "poltrone")}
                  >
                    {t("poltrone")}
                  </a>
                </li>
                <li>
                  <a
                      onClick={(e) =>
                          changePath("/outlet/search?category=tavoli")
                      }
                      className={getActiveClass("outlet", "tavoli")}
                  >
                    {t("tavoli")}
                  </a>
                </li>
                <li>
                  <a
                      onClick={(e) =>
                          changePath("/outlet/search?category=madie_mobili")
                      }
                      className={getActiveClass("outlet", "madie_mobili")}
                  >
                    {t("madie")}
                  </a>
                </li>
                <li>
                  <a
                      onClick={(e) =>
                          changePath("/outlet/search?category=madie_mobili")
                      }
                      className={getActiveClass("outlet", "madie_mobili")}
                  >
                    {t("mobili")}
                  </a>
                </li>
                <li>
                  <a
                      onClick={(e) =>
                          changePath("/outlet/search?category=oggettistica")
                      }
                      className={getActiveClass("outlet", "oggettistica")}
                  >
                    {t("oggettistica")}
                  </a>
                </li>
                <li>
                  <a
                      onClick={(e) =>
                          changePath("/outlet/search?category=complementi")
                      }
                      className={getActiveClass("outlet", "complementi")}
                  >
                    {t("complementi")}
                  </a>
                </li>
                <li>
                  <a
                      onClick={(e) =>
                          changePath("/outlet/search?category=tappeti")
                      }
                      className={getActiveClass("outlet", "tappeti")}
                  >
                    {t("tappeti")}
                  </a>
                </li>
              </ul>
            </li>
          </ul>

          <ul className="support-menu">
            {/*<li>*/}
            {/*  <a onClick={(e) => { ReactGA.event(`history_quotations_retrieved`); changePath("/quotations") }}>*/}
            {/*    {t("History of quotations")}*/}
            {/*  </a>*/}
            {/*</li>*/}
            {user && user?.role?.toUpperCase() === 'ADMIN' &&
              <li >
                <a onClick={(e) => changePath("/dashboard")}>
                  {t("Dashboard")}
                </a>
              </li>
            }
            <li>
              <Link className="link"
                //onClick={_ => { ReactGA.event(`download_trims`) }}
                to={trimPDFPaths[i18n.language.toUpperCase()]} target="_blank" download>
                {t("Download trims")}
              </Link>
            </li>
            <li>
              <Link className="link"
                //onClick={_ => { ReactGA.event(`download_general_condition_of_sale`) }}
                to={generalConditionsOfSale[i18n.language.toUpperCase()]} target="_blank" download>
                {t("General Condition Of Sale")}
              </Link>
            </li>
            <li>
              <Link className="link"
                //onClick={_ => { ReactGA.event(`download_user_manual`) }}
                to={userManual[i18n.language.toUpperCase()]} target="_blank" download>
                {t("User manual")}
              </Link>
            </li>
            <li>
              <a href="mailto:customer.it@calligaris.it">{t("support")}</a>
            </li>
          </ul>
        </div>

        <div className="lang">
          <div>
            <BootstrapSwitchButton
              checked={onlyProductAvailable === true}
              onlabel={t('only products available')}
              offlabel={t('all products')}
              onChange={(checked) => {
                setOnlyProductAvailable(checked);
                setTimeout(()=>{window.location.href = '/';},500);
              }}
              onstyle={'outline-dark'}
              offstyle={'outline-dark'}
              style="width-230 right switch-products"
          /></div>
          <div className={'m-2'}><span>{t('size units')} &nbsp;</span><BootstrapSwitchButton
              checked={sizeUnit === "decimal"}
              onlabel={t('decimal metric')}
              offlabel={t('imperial metric')}
              onChange={(checked) => {
                setSizeUnit(checked ? 'decimal': 'imperial');
                setTimeout(()=>{window.location.href = '/';},500);
              }}
              onstyle={'dark'}
              offstyle={'outline-dark'}
              style="width-150"
          /></div>
          <ul>
            {languages.map((lng) => {
              return (
                <li
                  className={lng.key === language ? "active" : ""}
                  key={lng.key}
                  onClick={() => {
                    localStorage.setItem("product", '');
                    setLanguage(lng.key);
                    setTimeout(()=>{window.location.href = '/';},500);
                  }}
                >
                  {lng.label}
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div
        className={`backdrop ${offCanvasOpen ? "open" : ""}`}
        onClick={() => setOffCanvasOpen(false)}
      ></div>
    </>
  );
}
