export const trimPDFPaths = {
    "EN_US" : "/finiture/CAL22A_finiture_en-es_062022.pdf",
    "ES-ES" : "/finiture/CAL22A_finiture_en-es_062022.pdf",
    "FR_FR" : "/finiture/CAL22A_finiture_fr-de_062022.pdf",
    "DE_DE" : "/finiture/CAL22A_finiture_fr-de_062022.pdf",
    "IT_IT" : "/finiture/CAL22A_finiture_it_062022.pdf",
    "EN_GB" : "/finiture/CAL22A_finiture_UK-IRL_062022.pdf"
}

export const generalConditionsOfSale = {
    "EN_US" : "/documents/CONDIZIONI GENERALI DI VENDITA_ Listini Digitali_ Italia _ 2022.pdf",
    "ES-ES" : "/documents/CONDIZIONI GENERALI DI VENDITA_ Listini Digitali_ Italia _ 2022.pdf",
    "FR_FR" : "/documents/CONDIZIONI GENERALI DI VENDITA_ Listini Digitali_ Italia _ 2022.pdf",
    "DE_DE" : "/documents/CONDIZIONI GENERALI DI VENDITA_ Listini Digitali_ Italia _ 2022.pdf",
    "IT_IT" : "/documents/CONDIZIONI GENERALI DI VENDITA_ Listini Digitali_ Italia _ 2022.pdf",
    "EN_GB" : "/documents/CONDIZIONI GENERALI DI VENDITA_ Listini Digitali_ Italia _ 2022.pdf",
}

export const userManual = {
    "EN_US" : "/documents/Calligaris_Manuale d'uso_Listino digitale_07072022.pdf",
    "ES-ES" : "/documents/Calligaris_Manuale d'uso_Listino digitale_07072022.pdf",
    "FR_FR" : "/documents/Calligaris_Manuale d'uso_Listino digitale_07072022.pdf",
    "DE_DE" : "/documents/Calligaris_Manuale d'uso_Listino digitale_07072022.pdf",
    "IT_IT" : "/documents/Calligaris_Manuale d'uso_Listino digitale_07072022.pdf",
    "EN_GB" : "/documents/Calligaris_Manuale d'uso_Listino digitale_07072022.pdf",
}