import React from "react";

import { ReactComponent as PictogramSVG } from "./../img/icons/pictogram.svg";

export default function FooterComponent() {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer-wrapper">
          <div className="logo">Calligaris</div>
          <div className="copy">
            © {new Date().getFullYear()} Calligaris S.p.A. a socio unico - P.IVA IT05617370969 -{" "}
            <a href="https://key-we.it" target="_blank">
              Credits
            </a>
          </div>
          <div className="pictogram">
            <PictogramSVG />
          </div>
        </div>
      </div>
    </div>
  );
}
