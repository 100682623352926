import React, { useState } from "react";
import { sendResetPwdRequest } from "../../actions/user.js";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useDocumentTitle from "../../helpers/useDocumentTitle.js";

import { ReactComponent as PictogramSVG } from "./../../img/icons/pictogram.svg";

function ForgotPwd() {
  const { t } = useTranslation();
  useDocumentTitle(t("Forgot password"))
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({ username: "" });
  const history = useHistory();
  const submitButton = async () => {
    try {
      setError("");
      setLoading(true);
      const response = await sendResetPwdRequest(details.username);
      setLoading(false);
      console.log({ response });
      history.goBack();
    } catch (err) {
      setError("Credenziali non valide");
      console.log(err);
      setLoading(false);
    }
  };
  console.log("LoginForm");
  return (
    <>
      <div
        className="page-background"
        style={{
          backgroundImage:
            "url(https://www.calligaris.com/media/immagini/1964_n_cs3434_S9F_cs3427_SQ5_cs5098-G-P_P175_P2C_cs6096-3B_cs7207-.jpg)",
        }}
      ></div>
      <div className="header transparent">
        <div className="container">
          <div className="header-wrapper">
            <Link to="/" className="logo">
              Calligaris
            </Link>
            <div className="pictogram">
              <PictogramSVG />
            </div>
          </div>
        </div>
      </div>

      <div className="main forgot-pwd">
        <div className="container">
          <div className="form-card">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submitButton();
              }}
            >
              <h1 className="form-title">{t("Forgot password")}</h1>
              <p className="form-description">
                {t(
                  "Insert your email. You will recive a link to set your new password."
                )}
              </p>
              <div className="form-row">
                <label for="text">{t("Username or email")}</label>
                <input
                  type="text"
                  name="text"
                  placeholder={t("Username or email")}
                  id="text"
                  onChange={(e) =>
                    setDetails({ ...details, username: e.target.value })
                  }
                  value={details.username}
                />
              </div>

              <div className="form-actions">
                <a href="mailto:customer.it@calligaris.it">{t("Need support?")}</a>

                <button className="button" type="submit" disabled={loading}>
                  {t("Send")}
                </button>
              </div>

              {error !== "" && (
                <div className={"form-alert danger"}>{error}</div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPwd;
