import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../helpers/useDocumentTitle";
import outlet from "../../img/outlet_categories/_outlet.png"
export default function Home() {
  console.log("home");
  const history = useHistory();
  const { t } = useTranslation();
  useDocumentTitle("Homepage")

  const changePath = (location) => {
    history.push(location);
  };


  return (
    <div className="main home">
      <div className="container">
        <div className="page-header">
          <h1 className="title">{t("Product catalog") + " " + new Date().getFullYear()}</h1>
        </div>

        <div className="grid">
          {/*<div className="category-card">*/}
          {/*  <div*/}
          {/*    className="category-card-wrapper"*/}
          {/*    onClick={() => changePath("/configured")}*/}
          {/*  >*/}
          {/*    <img*/}
          {/*      src="https://www.calligaris.com/media/immagini/1058_z_cs4124-FE_P94_P9C_cs2052_P94_S0Z_cs6096-1B_P151_P9C_P175.jpg"*/}
          {/*      alt=""*/}
          {/*    />*/}
          {/*    <div className="overlay"></div>*/}
          {/*    <div className="content">*/}
          {/*      <h2 className="title">{t("Configured")}</h2>*/}
          {/*    </div>*/}
          {/*  </div>*/}

          {/*  <ul className="subcategories">*/}
          {/*    <li>*/}
          {/*      <Link to="/configured/search?category=sedie">{t('sedie')}</Link>*/}
          {/*    </li>*/}
          {/*    <li>*/}
          {/*      <Link to="/configured/search?category=sgabelli">{t('sgabelli')}</Link>*/}
          {/*    </li>*/}
          {/*    <li>*/}
          {/*      <Link to="/configured/search?category=poltrone">{t('poltrone')}</Link>*/}
          {/*    </li>*/}
          {/*    <li>*/}
          {/*      <Link to="/configured/search?category=tavoli">{t('tavoli')}</Link>*/}
          {/*    </li>*/}
          {/*    <li>*/}
          {/*      <Link to="/configured/search?category=madie_mobili">{t('madie_mobili')}</Link>*/}
          {/*    </li>*/}
          {/*    <li>*/}
          {/*      <Link to="/configured/search?category=oggettistica">*/}
          {/*        {t('oggettistica')}*/}
          {/*      </Link>*/}
          {/*    </li>*/}
          {/*    <li>*/}
          {/*      <Link to="/configured/search?category=complementi">*/}
          {/*        {t('complementi')}*/}
          {/*      </Link>*/}
          {/*    </li>*/}
          {/*    <li>*/}
          {/*      <Link to="/configured/search?category=tappeti">{t('tappeti')}</Link>*/}
          {/*    </li>*/}
          {/*  </ul>*/}
          {/*</div>*/}

          {/*<div className="category-card">*/}
          {/*  <div*/}
          {/*    className="category-card-wrapper"*/}
          {/*    onClick={() => changePath("/configurable")}*/}
          {/*  >*/}
          {/*    <img*/}
          {/*      src="https://www.calligaris.com/media/immagini/1060_z_cs6098_SQM_cs6096-4A-5A_P151_P9C_cs3427_SQ5_cs7233_cs7255_V.jpg"*/}
          {/*      alt=""*/}
          {/*    />*/}
          {/*    <div className="overlay"></div>*/}
          {/*    <div className="content">*/}
          {/*      <h2 className="title">{t("Configurable")}</h2>*/}
          {/*    </div>*/}
          {/*  </div>*/}

          {/*  <ul className="subcategories">*/}
          {/*    <li>*/}
          {/*      <Link to="/configurable/search?category=divani">{t('divani')}</Link>*/}
          {/*    </li>*/}
          {/*    <li>*/}
          {/*      <Link to="/configurable/search?category=letti">{t('letti')}</Link>*/}
          {/*    </li>*/}
          {/*  </ul>*/}
          {/*</div>*/}
          <div className="category-card">
            <div
                className="category-card-wrapper"
                onClick={() => changePath("/outlet")}
            >
              <img
                  src={outlet}
                  alt=""
              />
              <div className="overlay"></div>
              <div className="content">
                <h2 className="title">{t("Outlet")}</h2>
              </div>
            </div>

            <ul className="subcategories">
              <li>
                <Link to="/outlet/search?category=sedie">{t('sedie')}</Link>
              </li>
              <li>
                <Link to="/outlet/search?category=sgabelli">{t('sgabelli')}</Link>
              </li>
              <li>
                <Link to="/outlet/search?category=tavoli">{t('tavoli')}</Link>
              </li>
              <li>
                <Link to="/outlet/search?category=madie_mobili">{t('madie_mobili')}</Link>
              </li>
              <li>
                <Link to="/outlet/search?category=oggettistica">
                  {t('oggettistica')}
                </Link>
              </li>
              <li>
                <Link to="/outlet/search?category=complementi">
                  {t('complementi')}
                </Link>
              </li>
              <li>
                <Link to="/outlet/search?category=tappeti">{t('tappeti')}</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
