import complementi from "./../img/categories/complementi.jpg"
import mobili_giorno from "./../img/categories/mobili-giorno.jpg"
import mobili_notte from "./../img/categories/mobili-notte.jpg"
import oggettistica from "./../img/categories/oggettistica.jpg"
import poltrone from "./../img/categories/poltrone.jpg"
import sedie from "./../img/categories/sedie.jpg"
import sgabelli from "./../img/categories/sgabelli.jpg"
import tappeti from "./../img/categories/tappeti.jpg"
import tavoli from "./../img/categories/tavoli.jpg"
import complementiOutlet from "./../img/outlet_categories/complementi.png"
import mobili_giornoOutlet from "./../img/outlet_categories/mobili_giorno.png"
import mobili_notteOutlet from "./../img/outlet_categories/mobili_notte.png"
import oggettisticaOutlet from "./../img/outlet_categories/oggettistica.png"
import sedieOutlet from "./../img/outlet_categories/sedie.png"
import sgabelliOutlet from "./../img/outlet_categories/sgabelli.png"
import tappetiOutlet from "./../img/outlet_categories/tappeti.png"
import tavoliOutlet from "./../img/outlet_categories/tavoli.png"
import divani from "./../img/categories/divani.jpg"
import letti from "./../img/categories/letti.jpg"
import placeholder from "./../img/placeholder.jpg"
import A1Q00 from "./../img/icons/sofa-icons/A1Q00.jpg"
import C1000 from "./../img/icons/sofa-icons/C1000.jpg"
import C100M from "./../img/icons/sofa-icons/C100M.jpg"
import D2000 from "./../img/icons/sofa-icons/D2000.jpg"
import D200M from "./../img/icons/sofa-icons/D200M.jpg"
import D2T00 from "./../img/icons/sofa-icons/D2T00.jpg"
import D3000 from "./../img/icons/sofa-icons/D3000.jpg"
import D300M from "./../img/icons/sofa-icons/D300M.jpg"
import D3T00 from "./../img/icons/sofa-icons/D3T00.jpg"
import D3T0M from "./../img/icons/sofa-icons/D3T0M.jpg"
import H2000 from "./../img/icons/sofa-icons/H2000.jpg"
import H200M from "./../img/icons/sofa-icons/H200M.jpg"
import L1000 from "./../img/icons/sofa-icons/L1000.jpg"
import L2000 from "./../img/icons/sofa-icons/L2000.jpg"
import L200G from "./../img/icons/sofa-icons/L200G.jpg"
import L3000 from "./../img/icons/sofa-icons/L3000.jpg"
import L300M from "./../img/icons/sofa-icons/L300M.jpg"
import L3A00 from "./../img/icons/sofa-icons/L3A00.jpg"
import P1000 from "./../img/icons/sofa-icons/P1000.jpg"
import P1T00 from "./../img/icons/sofa-icons/P1T00.jpg"
import U1000 from "./../img/icons/sofa-icons/U1000.jpg"
import U100M from "./../img/icons/sofa-icons/U100M.jpg"


export const configuredCategoryImgs = {
    complementi,
    mobili_giorno ,
    mobili_notte,
    oggettistica,
    poltrone,
    sedie,
    sgabelli,
    tappeti,
    tavoli,
}

export const configuredOutletCategoryImgs = {
    complementi: complementiOutlet,
    mobili_giorno : mobili_giornoOutlet,
    mobili_notte: mobili_notteOutlet,
    oggettistica: oggettisticaOutlet,
    sedie: sedieOutlet,
    sgabelli: sgabelliOutlet,
    tappeti: tappetiOutlet,
    tavoli: tavoliOutlet,
}

export const configurableCategoryImgs = {
    divani,
    letti
}

export const retrieveMTOImages = (item) => {
    let usePlaceholder = false;
    const findAlternativeImg = () => {
        if(!usePlaceholder){
            let pVersion = item.product.parentVersion.split(' ').join('-');
            if(pVersion.split('-')[pVersion.split('-').length-1] !== 'MTO')
                pVersion += '-MTO';
            
            usePlaceholder = true;
            return `https://www.calligaris.com/media/configsHires/${pVersion}.png`
        }
        return placeholder;
    }

    return (
        <img src={item.img} alt={item.title} onError={(currentTarget) => {
            currentTarget.target.src = findAlternativeImg();
          }} />
    )
}

// SOFA ICONS

const sofaIcons = {
    A1Q00,
    C1000,
    C100M,
    D2000,
    D200M,
    D2T00,
    D3000,
    D300M,
    D3T00,
    D3T0M,
    H2000,
    H200M,
    L1000,
    L2000,
    L200G,
    L3000,
    L300M,
    L3A00,
    P1000,
    P1T00,
    U1000,
    U100M,
}

export const retrieveSofaIconSrc = (code) => {
    return sofaIcons[code];
}