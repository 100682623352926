import { useTranslation } from "react-i18next";
import { retrieveMTOImages } from "../../helpers/imgRetrievers";
import { ProductTableInfo } from "./ProductTableInfo";
import placeholder from "./../../img/placeholder.jpg"


export const ProductTable = ({ items }) => {

  const { t } = useTranslation();

  const getProductImg = (isD3, product, imgLink) => {
    if (isD3 || (!isD3 && product.procurementMethod !== 'MTO'))
      return <img src={product.img} alt="" onError={(currentTarget) => {
        currentTarget.target.src = placeholder;
      }} />
    else retrieveMTOImages({ img: imgLink, product })
  }

  return <table>
    <tbody>
      {items.map((item, index) => {
        let product = item.product;
        let isD3 = !!product.options;
        let imgLink = `https://www.calligaris.com/media-prods/436x320/${product.parentVersion || product.productCode}.jpg`;
        if (!isD3) {
          imgLink = `https://www.calligaris.com/media/configsHires/${product.productCode}.png`;
        }
        product.img = imgLink;
        const ImgComponent = getProductImg(isD3, product, imgLink)
        return (
          <tr key={index}>
            <ProductTableInfo imgComponent={ImgComponent} product={product} />
            <td className="quantity">
              {t("Quantity")}:&nbsp;{item.quantity}
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>

}