import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import React, { useEffect, useState } from "react";
import ApiService from "../../actions/apiService";
import { useTranslation } from "react-i18next";
import ReactGA from 'react-ga4'

import { ReactComponent as SarchSVG } from "./../../img/icons/search.svg";
import FilterSelect from "./FilterSelect";
import { retrieveSofaIconSrc } from "../../helpers/imgRetrievers";
import placeholder from "../../img/placeholder.jpg"
import { MAP_CATEGORY_TO_G4, MAP_PART_TO_G4 } from "../../helpers/mappers";

const FilterByPart = (props) => {
  const { t } = useTranslation();
  console.log("FilterByPart", props);
  const [loading, setLoading] = React.useState(false);
  const [materiali, setMateriali] = React.useState([]);
  const [finiture, setFiniture] = React.useState([]);
  const [tipologie, setTipologie] = React.useState([]);
  const [dimensioni, setDimensioni] = React.useState([]);
  const [rivestimenti, setRivestimenti] = React.useState([]);
  const [rivestimentiFiltered, setFilteredRivestimenti] = React.useState([]);
  const [groupedRivestimenti, setGroupedRivestimenti] = React.useState([]);
  const [priceCategories, setPriceCategories] = React.useState([]);

  const [piedini, setPiedini] = React.useState([]);
  let initMateriali = [];
  let initFiniture = [];
  let initDimensioni = [];
  let initRivestimenti = [];
  let initPiedini = [];
  let productType = props.category.endsWith("_configurable") ? "CONFIGURABLE" : "CONFIGURED";
  let multiple = true;
  if (props.single) {
    multiple = false;
  }

  if (props.filter) {
    console.log(props.filter);
    if (productType === "CONFIGURABLE") {
      initDimensioni =
        props.filter.split(";").length >= 1
          ? props.filter.split(";")[0].split(",")
          : [];
      initRivestimenti =
        props.filter.split(";").length >= 2
          ? props.filter.split(";")[1].split(",")
          : [];
      initPiedini =
        props.filter.split(";").length >= 3
          ? props.filter.split(";")[2].split(",")
          : [];
      //console.log("initDimensioni", initDimensioni);
      //console.log("initRivestimenti", initRivestimenti);
      //console.log("initPiedini", initPiedini);
    } else if (productType === "CONFIGURED") {
      initMateriali =
        props.filter.split(";").length >= 1
          ? props.filter.split(";")[0].split(",")
          : [];
      initFiniture =
        props.filter.split(";").length >= 2
          ? props.filter.split(";")[1].split(",")
          : [];
      //console.log("initMateriali", initDimensioni);
      //console.log("initFinishing", initDimensioni);
    }
  }





  const [selectedMateriali, setSelectedMateriali] = useState(initMateriali);
  const [selectedFiniture, setSelectedFiniture] = useState(initFiniture);
  const [selectedTipologie, setSelectedTipologie] = useState([]);
  const [selectedDimensioni, setSelectedDimensioni] = useState(initDimensioni);
  const [selectedRivestimenti, setSelectedRivestimenti] = useState(initRivestimenti);
  const [selectedPiedini, setSelectedPiedini] = useState(initPiedini);
  let initPriceCategories = rivestimenti.filter(riv => riv.codice == initRivestimenti)[0]?.priceCategory;
  const [selectedPriceCategories, setSelectedPriceCategories] = React.useState(null);
  React.useEffect(() => {
    async function fetchData() {
      await getFilterByCategory(props.category, props.part, props.productType);
      await populateInitSelection();
    }
    fetchData();
  }, [props.category, props.part]);

  React.useEffect(async () => {
    if (initMateriali.length > 0 && materiali.length >0) {
      let initMaterialiObj = materiali.filter((mat) =>
        initMateriali.includes(mat.codice)
      );
      setSelectedMateriali(initMaterialiObj);
    }
  }, [materiali]);
  React.useEffect(async () => {
    if (initFiniture.length > 0 && finiture.length >0) {
      let initFinitureObj = finiture.filter((fin) =>
        initFiniture.includes(fin.codice)
      );
      setSelectedFiniture(initFinitureObj);
    }
  }, [finiture]);

  React.useEffect(async () => {
    if (initDimensioni.length > 0 && dimensioni.length >0) {
      let initDimensioniObj = dimensioni.filter((el) =>
        initDimensioni.includes(el.codice)
      );
      setSelectedDimensioni(initDimensioniObj);
    }
  }, [dimensioni]);

  React.useEffect(async () => {
    if (initRivestimenti.length > 0 && rivestimenti.length >0) {
      let initRivestimentiObj = rivestimenti.filter((el) =>
        initRivestimenti.includes(el.codice)
      );
      setSelectedRivestimenti(initRivestimentiObj);
    }
  }, [rivestimenti]);
  React.useEffect(async () => {
    if (initPiedini.length > 0 && piedini.length >0) {
      let initPiediniObj = piedini.filter((el) =>
        initPiedini.includes(el.codice)
      );
      setSelectedPiedini(initPiediniObj);
    }
  }, [piedini]);




  React.useEffect(() => {
    populateFilter();
  }, [selectedMateriali, selectedFiniture, selectedTipologie, selectedDimensioni, selectedRivestimenti, selectedPiedini]);

  let populateFilter = function () {
    if(selectedMateriali.length >0 && typeof selectedMateriali[0] === "string" ||
        selectedFiniture.length >0 && typeof selectedFiniture[0] === "string" ||
        selectedTipologie.length >0 && typeof selectedTipologie[0] === "string" ||
        dimensioni.length > 0 && typeof selectedDimensioni[0] === "string"
    ) {
      console.log("bypass populate filter");
      return;
    }
    let filter = [
      selectedMateriali.map((mat) => mat.codice).join(","),
      selectedFiniture.map((fin) => fin.codice).join(","),
      props.part,
    ].join(";");

    if (selectedTipologie.length > 0) {
      filter = [
        selectedTipologie.map((tip) => tip.codice).join(","),
        "",
        "",
      ].join(";");
    }
    //prodotti configurabili
    if (dimensioni.length > 0) {
      filter = [
        selectedDimensioni.map((tip) => tip.codice).join(","),
        selectedRivestimenti.map((tip) => tip.codice).join(","),
        selectedPiedini.map((tip) => tip.codice).join(","),
      ].join(";");
    }
    console.log("set filter populateFilter", filter);
    props.setFilter(filter);
  };

  const getFilterValues = async (category, part, type, productType) => {
    let categorySplitted = category.split("_")[0];
    if (categorySplitted === 'madie') {
      categorySplitted = "madie_mobili"
    }
    var query = {
      category: categorySplitted,
      type,
    };
    if (part) {
      query.partCode = part;
    }
    let path = "/products/filter-values";
    if(productType === "outlet"){
      query.productGroup = "outlet";
    }
    if (category.split("_").length === 2 && category.split("_")[1] === "configurable") {
      path = '/ditre/filter-values';
      if (part) {
        delete query.partCode;
        query.CodiceCAL = part;
      }
    }
    let result = await ApiService.get(path, query);
    localStorage.setItem(type, JSON.stringify(result));
    return result;
  };

  const getFilterByCategory = async (category, part, productType) => {
    setLoading(true);
    console.log("getFilterByCategory", category, part, productType);
    let materiali = [],
      tipologie = [],
      finiture = [],
      dimensioni = [],
      rivestimenti = [],
      piedini = [];
    switch (category) {
      case "sedie":
      case "sgabelli":
      case "poltrone":
      case "tavoli":
      case "madie_mobili":
      case "complementi":
        materiali = await getFilterValues(category, part, "Materiali", productType);
        finiture = await getFilterValues(category, part, "Finiture", productType);
        break;
      case "oggettistica":
        tipologie = await getFilterValues(category, part, "Tipologia", productType);
        break;
      case "tappeti":
        materiali = await getFilterValues(category, part, "Materiali", productType);
        break;
      case "letti":
      case "divani":
        break;
      case "letti_configurable":
      case "divani_configurable":
        dimensioni = await getFilterValues(category, part, 'dimensioni', productType);
        dimensioni = dimensioni.filter(dim => {
          return dim.description1 !== "Altro"
        }).map(dim=>{
          return { codice: dim.codice, description1: dim.codice + " - " + dim.description1}

        })
        rivestimenti = await getFilterValues(category, part, 'rivestimenti', productType);
        let priceCategories = rivestimenti.map(riv => {
          return { codice: riv.priceCategoryDescription, description1: riv.priceCategoryDescription }
        });
        priceCategories.sort((a, b) => a.description1.localeCompare(b.description1))
        priceCategories = [...new Map(priceCategories.map((item) => [item["codice"], item])).values()];
        let grpRivestimenti = groupBy(rivestimenti, rivestimenti => rivestimenti.priceCategoryDescription);
        setGroupedRivestimenti(grpRivestimenti);
        setPriceCategories(priceCategories);

        piedini = await getFilterValues(category, part, 'piedini', productType);
        break;
    }
    setTipologie(tipologie);
    setFiniture(finiture);
    setMateriali(materiali);
    setDimensioni(dimensioni);
    setRivestimenti(rivestimenti);
    setPiedini(piedini);
    setLoading(false);
  };

  const populateInitSelection = async function () {

  }

  const onSetSelectedPriceCategories = function (pc) {
    setSelectedPriceCategories(pc);
    if (pc != priceCategories) {

      let rivestimenti = groupedRivestimenti.get(pc[0]?.codice);
      rivestimenti?.sort((a, b) => a.description1.localeCompare(b.description1));
      setFilteredRivestimenti(rivestimenti || []);
      setSelectedRivestimenti([]);
    }
  }
  if (loading) {
    return <>{t('Loading')}...</>
  }
  const renderMenuItem = (option, props, index) => {
    return <><img src={`/rivestimenti_DITRE/${option?.codice?.toUpperCase()}.jpg`}
      height={22} width={22} style={{ 'width': '22px', 'display': 'inline' }} />
      &nbsp;{option.description1}</>
  }
  const renderMenuDimensionItem = (option, props) => {
    const sofaIconDivStyle = { 'width': '50px', 'overflow': 'hidden', 'position': 'relative', 'minWidth': '50px' };
    const sofaIconImgStyle = { 'position': 'absolute', 'top': '-11px' };
    const placeholderDivStyle = { 'width': '50px', 'overflow': 'hidden', 'position': 'relative', 'height': '24px', 'padding': '0 10px', 'minWidth': '50px' }
    const sofaIcon = retrieveSofaIconSrc(option?.codice?.toUpperCase());
    return (
      <>
        <div style={{ 'display': 'flex', 'gap': '5px' }}>
          <div style={sofaIcon ? sofaIconDivStyle : placeholderDivStyle}>
            <img src={sofaIcon || placeholder} style={sofaIcon ? sofaIconImgStyle : {}} />
          </div>
          <span style={{ 'overflow': 'hidden', 'textOverflow': 'ellipsis' }}>{option.description1}</span>
        </div></>
    )
  }
  return (
    <>
      {materiali.length > 0 && (
        <FilterSelect label={t("Materials")}
          multiple={multiple}
          setSelected={(event) => {
            if (event.length > selectedMateriali.length) {
              const descNoSpace = event.at(-1).description1.replace(/\W+/g, "_")
              ReactGA.event(`${MAP_CATEGORY_TO_G4[props.category] ?? ""}${MAP_PART_TO_G4[props.part] ?? ""}mat_${descNoSpace}`)
            }
            setSelectedMateriali(event)
          }}
          options={materiali}
          placeholder={t("Filter by material")}
          selected={selectedMateriali} />
      )}
      {finiture.length > 0 && (
        <FilterSelect label={t("Finishings")}
          multiple={multiple}
          setSelected={(event) => {
            if (event.length > selectedFiniture.length) {
              const descNoSpace = event.at(-1).description1.replace(/\W+/g, "_")
              ReactGA.event(`${MAP_CATEGORY_TO_G4[props.category] ?? ""}${MAP_PART_TO_G4[props.part] ?? ""}fin_${descNoSpace}`)
            }
            setSelectedFiniture(event)
          }}
          options={finiture}
          placeholder={t("Filter by finishing")}
          selected={selectedFiniture} />
      )}
      {tipologie.length > 0 && (
        <FilterSelect label={t("Type")}
          multiple={multiple}
          setSelected={(event) => {
            if (event.length > selectedTipologie.length) {
              const descNoSpace = event.at(-1).description1.replace(/\W+/g, "_")
              ReactGA.event(`${MAP_CATEGORY_TO_G4[props.category] ?? ""}${MAP_PART_TO_G4[props.part] ?? ""}typ_${descNoSpace}`)
            }
            setSelectedTipologie(event)
          }}
          options={tipologie}
          placeholder={t("Filter by finishing")}
          selected={selectedTipologie} />
      )}
      {dimensioni.length > 0 && (
        <FilterSelect label={t("Dimension")}
          multiple={multiple}
          setSelected={(event) => {
            if (event.length > selectedDimensioni.length) {
              const descNoSpace = event.at(-1).description1.replace(/\W+/g, "_")
              ReactGA.event(`${MAP_CATEGORY_TO_G4[props.category] ?? ""}${MAP_PART_TO_G4[props.part] ?? ""}dim_${descNoSpace}`);
            }
            setSelectedDimensioni(event)
          }}
          options={dimensioni}
          placeholder={t("Filter by dimension")}
          renderMenuItemChildren={
            props.category.split('_')[0] === 'divani'
              ? renderMenuDimensionItem
              : undefined}
          selected={selectedDimensioni} />
      )}
      {rivestimenti.length > 0 && !props.subcategories && (
        <FilterSelect label={t("Coating")}
          multiple={multiple}
          setSelected={(event) => {
            if (event.length > selectedRivestimenti.length) {
              const descNoSpace = event.at(-1).description1.replace(/\W+/g, "_")
              ReactGA.event(`${MAP_CATEGORY_TO_G4[props.category] ?? ""}${MAP_PART_TO_G4[props.part] ?? ""}coa_${descNoSpace}`);
            }
            setSelectedRivestimenti(event)
          }}
          options={rivestimenti}
          placeholder={t("Filter by coating")}
          renderMenuItemChildren={renderMenuItem}
        />
      )}
      {priceCategories.length > 0 && props.subcategories && (
        <>
          <FilterSelect label={t("Coating Group")}
            multiple={multiple}
            setSelected={(event) => {
              if (!selectedPriceCategories || event.length > selectedPriceCategories.length) {
                const descNoSpace = event.at(-1).description1.replace(/\W+/g, "_")
                ReactGA.event(`${MAP_CATEGORY_TO_G4[props.category] ?? ""}${MAP_PART_TO_G4[props.part] ?? ""}cg_${descNoSpace}`);
              }
              onSetSelectedPriceCategories(event)
            }}
            options={priceCategories}
            placeholder={t("Filter by coating group")}
            selected={selectedPriceCategories} />
          <FilterSelect label={t("Coating")}
            multiple={multiple}
            setSelected={(event) => {
              if (event.length > selectedRivestimenti.length) {
                const descNoSpace = event.at(-1).description1.replace(/\W+/g, "_")
                ReactGA.event(`${MAP_CATEGORY_TO_G4[props.category] ?? ""}${MAP_PART_TO_G4[props.part] ?? ""}coa_${descNoSpace}`);
              }
              setSelectedRivestimenti(event)
            }}
            options={rivestimentiFiltered}
            placeholder={t("Filter by coating")}
            selected={selectedRivestimenti}
            renderMenuItemChildren={renderMenuItem}

          />
        </>
      )}
      {piedini.length > 0 && (
        <FilterSelect label={t("Foot")}
          multiple={multiple}
          setSelected={(event) => {
            if (event.length > selectedPiedini.length) {
              const descNoSpace = event.at(-1).description1.replace(/\W+/g, "_")
              ReactGA.event(`${MAP_CATEGORY_TO_G4[props.category] ?? ""}${MAP_PART_TO_G4[props.part] ?? ""}foot_${descNoSpace}`);
            }
            setSelectedPiedini(event)
          }}
          options={piedini}
          placeholder={t("Filter by foot")}
          selected={selectedPiedini} />
      )}
    </>
  );
};

export default FilterByPart;


/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
//export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
//    const map = new Map<K, Array<V>>();
function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}