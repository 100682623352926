export function sanitizeNavigatorLanguage(lng) {
  lng = lng || '';
  lng = lng.toUpperCase()
  switch (true){
    case /EN[-_]GB/.test(lng):
      return 'EN_GB';
    case /EN.*/.test(lng):
      return 'EN_US';
    case /DE.*/.test(lng):
      return 'DE_DE';
    case /FR.*/.test(lng):
      return 'FR_FR';
    case /IT.*/.test(lng):
      return 'IT_IT';
    case /ES.*/.test(lng):
      return 'ES_ES';
    default:
      return 'EN_US';
  }
}