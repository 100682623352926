let initialState = {
    accessToken: null,
    refreshToken: null,
    claims: {},
    refreshClaims: {},
    loaded: false //the checkLogin method is async so we need to prevent any render before it finishes
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_TOKEN': {
            sessionStorage.setItem('accessToken', action.payload.accessToken);
            localStorage.setItem('refreshToken', action.payload.refreshToken);

            return {
                ...state,
                ...action.payload,
                loaded: true
            };
        }
        case 'CLEAR_TOKEN': {

            sessionStorage.removeItem('accessToken');
            sessionStorage.removeItem('user');
            localStorage.removeItem('refreshToken');
            let cart = localStorage.getItem('react-use-cart');
            let lang = localStorage.getItem('language');
            let sizeUnit = localStorage.getItem('sizeUnit');
            localStorage.clear();
            sessionStorage.clear();
            localStorage.setItem('react-use-cart',cart);
            if(lang){
                localStorage.setItem('language',lang || "it_it");
            }
            localStorage.setItem('sizeUnit',sizeUnit || "decimal");
            return {
                ...initialState,
                loaded: true
            };
        }
        default:
            return state;
    }
};

export default reducer;