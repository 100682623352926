import React from "react";
import { useCart } from "react-use-cart";
import { Modal } from "react-bootstrap";
import ApiService from "../../actions/apiService";
import * as PropTypes from "prop-types";
import PriceUtils from "../../helpers/price";
import { useTranslation } from "react-i18next";

import { ReactComponent as ChevronSVG } from "./../../img/icons/chevron.svg";
import { ReactComponent as MinusSVG } from "./../../img/icons/minus.svg";
import { ReactComponent as PictogramSVG } from "./../../img/icons/pictogram.svg";
import { ReactComponent as PlusSVG } from "./../../img/icons/plus.svg";
import { useHistory } from "react-router-dom";
import {retrieveMTOImages, retrieveSofaIconSrc} from "../../helpers/imgRetrievers";
import placeholder from "./../../img/placeholder.jpg"
import ReactGA from 'react-ga4'


const renderMenuDimensionItem = (dimensioneCodice) => {
  const sofaIconDivStyle = {'width': '50px','overflow':'hidden','position':'relative','minWidth':'50px'};
  const sofaIconImgStyle = {'position':'absolute','top':'-11px'};
  const placeholderDivStyle = {'width': '50px','overflow':'hidden','position':'relative','height':'24px','padding':'0 10px','minWidth':'50px'}
  const sofaIcon = retrieveSofaIconSrc(dimensioneCodice?.toUpperCase());
  return (
      <>
        <img src={sofaIcon || placeholder}/>
      </>
  )
}

function NumericInput(props) {
  return null;
}

NumericInput.propTypes = {
  style: PropTypes.bool,
  className: PropTypes.string,
};

const Cart = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  const {
    isEmpty,
    items,
    cartTotal,
    updateItemQuantity,
    removeItem,
    emptyCart,
    clearCartMetadata,
    metadata,
  } = useCart();

  let taxTotal = items.map(item => item.tax*item.quantity).reduce((accumulator, current) => {
    return accumulator + current;
  }, 0);
  const history = useHistory();
  const changePath = (location) => {
    history.push(location);
  };
  const { t } = useTranslation();
  const currency = items[0]?.currency;
  const [cartDiscountedTotal, setCartDiscountedTotal] =
    React.useState(cartTotal);
  let [discount, setDiscount] = React.useState(
    metadata && metadata.discount && metadata.discount.value
      ? metadata.discount.value
      : 0
  );
  const [transportationFee, setTransportationFee] = React.useState(
      metadata && metadata.transportationFee && metadata.transportationFee.value
          ? metadata.transportationFee.value
          : 0
  );
  const [notes, setNotes] = React.useState(
      metadata && metadata.notes ? metadata.notes: ''
  );
  const [quotationName, setQuotationName] = React.useState(
    metadata?.name ? metadata.name : ""
  );
  const [personalDetails, setPersonalDetails] = React.useState(
    metadata && metadata.personalDetails ? metadata.personalDetails : {
      clientName: '',
      clientSurname: '',
      clientAddress: '',
      clientEmail: '',
      clientPhoneNumber: ''
    }  
  )

  const [emailError, setEmailError] = React.useState('');
  const [genericQuotationError, setGenericQuotationError] = React.useState('');

  const [showModal, setShowModal] = React.useState(false);
  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => {
    setShowModal(true)
  };

  const saveQuotation = async () => {
    await saveQuotationRequest();
    setShowModal(true);
  };

  const updateDiscountedTotal = function () {
    let disc = discount;
    if(isNaN(disc) || disc < 0){
      disc = 0;
    } else if (disc >100){
      disc = 100;
    }
    if(isNaN(taxTotal)){
      taxTotal = 0
    }

    setCartDiscountedTotal((((cartTotal) * (100 - disc)) / 100) + taxTotal+ transportationFee);
  };

  React.useEffect(updateDiscountedTotal, [discount, transportationFee, cartTotal]);

  const saveQuotationRequest = async () => {
    try{
      if (metadata.quotationId) {
        await updateQuotation(metadata.quotationId, items);
        /* window.gtag('event', 'quotation_modified', {
          'event_callback': () => {
            console.log("quotation modified event sent to google analytics")
          }
        }); */
        ReactGA.event('quotation_modified')
      } else {
        await createQuotation(items);
        /* window.gtag('event', 'quotation_created', {
          'event_callback': () => {
            console.log("quotation created event sent to google analytics")
          }
        }); */
        ReactGA.event('quotation_created')
      }

      changePath("/quotations");
    } catch (e) {
      console.log('err',e);

      if (e.message && e.message[0] === 'clientEmail must be an email') {
        setEmailError('Please, check the format of your email.')
      }

      if (e.status && e.status !== 200) {
        setGenericQuotationError("An error occurred saving quotation. Please check products on your cart.");
      }
    }
  };
  const createQuotation = async (prod) => {
    const products = prod.map((product) => {

      return {
        productCode: product.articleCode || product.codice,
        quantity: product.quantity,
        options: product.options,
        isD3: !!product.isD3,
        productGroup: product.productType
      };
    });
    const discountObj = {
      category: "Percentage",
      value: parseInt(discount),
    };
    const transportationFeeObj = {
      category: "Fixed",
      value: parseInt(transportationFee),
    };
    const request = {
      products,
      discount: discountObj,
      transportationFee: transportationFeeObj,
      name: quotationName,
      notes,
      ...personalDetails
    };

    await ApiService.post("/quotations", request);
    emptyCart();
  };

  const updateQuotation = async (quotationId, prod) => {
    const products = prod.map((product) => {
      return {
        productCode: product.articleCode || product.codice,
        quantity: product.quantity,
        options: product.options,
        isD3: !!product.isD3,
      };
    });
    const discountObj = {
      category: "Percentage",
      value: parseInt(discount),
    };
    const transportationFeeObj = {
      category: "Fixed",
      value: parseInt(transportationFee),
    };
    const request = {
      products,
      discount: discountObj,
      transportationFee: transportationFeeObj,
      name: quotationName,
      notes,
      ...personalDetails
    };

    await ApiService.put("/quotations/" + quotationId, request);
    emptyCart();
  };

  if (isEmpty) return <h5 className="empty-cart">{t("quotation is empty")}</h5>;

  const validateEmail = () => {
    let regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if(!regex.test(personalDetails.clientEmail)){
      return false;
    }
    return true;
    
  }

  return (
    <>
      <div className="grid">
        <div className="left">
          <div className="products">
            <table>
              <tbody>
                {items.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="info">
                        <div className="image-details">
                          <div className="image">
                          { !item.product || item.product.procurementMethod !== 'MTO' 
                            ? <img src={item.img} alt={item.title} onError={(currentTarget) => {
                              console.log(item);
                              currentTarget.target.src = placeholder; }} /> 
                            : retrieveMTOImages(item)}
                          </div>
                          <div className="details">
                            <div className="title">{item.title}</div>
                            <div className="description">
                              {item.isD3 && (item.codice || "")}
                              {!item.isD3 && ((item.product?.parentVersion || "") +(item.description3 || "")  + " " + item.description2)}
                            </div>
                            <div className="price">
                              {PriceUtils.getPrice(item.price, item.currency)}
                            </div>
                           { item.tax && <div className="price">
                             {t("Ecotax") + ":" + PriceUtils.getPrice(
                                  Number(item.tax),
                                  item.currency || "EUR"
                              )}
                            </div> }
                            <div className="options">
                              {item.options &&
                                Object.keys(item.options).map((key) => {
                                  return (
                                    <span>
                                      {key}: {item.options[key]}
                                    </span>
                                  );
                                })}
                            </div>
                          </div>
                          { item.dimensioneCodice && <div className="dimension-icon">
                            {renderMenuDimensionItem(item.dimensioneCodice)}

                          </div>
                          }
                        </div>
                      </td>
                      <td className="actions">
                        <div className="actions-wrapper">
                          <div className="quantity">
                            <button
                              className="reduce"
                              onClick={() => {
                                let newQ =
                                  item.quantity - item.quantityPerPackage;
                                newQ =
                                  newQ >= item.minimumBatch
                                    ? newQ
                                    : item.minimumBatch;
                                updateItemQuantity(item.id, newQ);
                              }}
                            >
                              <span className="label">{t("Reduce")}</span>
                              <MinusSVG />
                            </button>
                            <span className="input">{item.quantity}</span>
                            <button
                              className="add"
                              onClick={() =>
                                updateItemQuantity(
                                  item.id,
                                  item.quantity + item.quantityPerPackage
                                )
                              }
                            >
                              <span className="label">{t("Add")}</span>
                              <PlusSVG />
                            </button>
                          </div>

                          <button
                            onClick={() => removeItem(item.id)}
                            className="remove button-alt"
                          >
                            {t("remove")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            { !isNaN(taxTotal) && taxTotal >0 && <div className="subtotal-row">
              <div className="left">
                <div className="pictogram">
                  <PictogramSVG />
                </div>
                <div className="">{t("Total Tax")}</div>
              </div>
              <div className="right">
                {PriceUtils.getPrice(taxTotal, currency)}
              </div>
            </div>
            }
            <div className="subtotal-row">
              <div className="left">
                <div className="pictogram">
                  <PictogramSVG />
                </div>
                <div className="">{t("Subtotal")}</div>
              </div>
              <div className="right">
                {PriceUtils.getPrice(cartTotal, currency)}
              </div>
            </div>
          </div>
          
          <div className="personal-details-inputs">
            <label className="label">{t("Name")}</label>
            <input
              className="personal-details-input"
              type="text"
              placeholder="-"
              value={personalDetails.clientName}
              onChange={(event) => {
                setPersonalDetails(prevState => ({
                  ...prevState,
                  clientName:event.target.value
                  })
              )}}
            />
            <label className="label">{t("Surname")}</label>
            <input
              className="personal-details-input"
              type="text"
              placeholder="-"
              value={personalDetails.clientSurname}
              onChange={(event) => {
                setPersonalDetails(prevState => ({
                  ...prevState,
                  clientSurname:event.target.value
                  })
              )}}
            />
            <label className="label">{t("Address")}</label>
            <input
              className="personal-details-input"
              type="text"
              placeholder="-"
              value={personalDetails.clientAddress}
              onChange={(event) => {
                setPersonalDetails(prevState => ({
                  ...prevState,
                  clientAddress:event.target.value
                  })
              )}}
            />
            <div className="email-input">
              <label className="label">{t("Mail")}</label>
              {emailError !== '' && <p>{t(emailError)}</p>}
            </div>
            <input
              className="personal-details-input"
              type="text"
              placeholder="-"
              value={personalDetails.clientEmail}
              onBlur={() => !validateEmail() ? setEmailError('Please, check the format of your email.') : setEmailError('')}
              onChange={(event) => {
                setEmailError('');
                setPersonalDetails(prevState => ({
                  ...prevState,
                  clientEmail:event.target.value
                  })
              )}}
            />
            <label className="label">{t("Phone Number")}</label>
            <input
              className="personal-details-input"
              type="text"
              value={personalDetails.clientPhoneNumber}
              placeholder='+39 000 000 0000'
              onChange={(event) => {
                setPersonalDetails(prevState => ({
                  ...prevState,
                  clientPhoneNumber:event.target.value 
                  }));
                }
              }
            />
          </div>

          <div className="notes">
            <h2>{t("Notes")}</h2>
            <textarea placeholder={t("Insert a note here")+ "..."} value={notes}
                      onChange={(e)=> setNotes(e.target.value)} />
          </div>
        </div>

        <div className="right">
          <h3 className="title">{t("Quotation")}</h3>

          <div className="subtotal">
            <div>{t("Subtotal")}</div>
            <div>{PriceUtils.getPrice(cartTotal, currency)}</div>
          </div>
          { !isNaN(taxTotal) && taxTotal >0 && <div className="subtotal">
            <div>{t("Total EcoTax")}</div>
            <div>{PriceUtils.getPrice(taxTotal, currency)}</div>
          </div> }
          <div className="transportation-fee">
            <div>{t("Transportation fee")}</div>
            <div className="transportation-fee-input">
              <input
                  min="0"
                  max="100"
                  type="number"
                  value={transportationFee}
                  onChange={(event) => {
                    setTransportationFee(parseInt(event.target.value));
                  }}
              />
              <div className="controls">
                <button
                    className="increase"
                    onClick={() => setTransportationFee(transportationFee + 1)}
                >
                  <span className="label">{t("Increase")}</span>
                  <ChevronSVG />
                </button>
                <button
                    className="reduce"
                    onClick={() => {
                      let newD = transportationFee - 1 >= 0 ? transportationFee - 1 : 0;
                      setTransportationFee(newD);
                    }}
                >
                  <span className="label">{t("Reduce")}</span>
                  <ChevronSVG />
                </button>
              </div>
              <div className="symbol">{PriceUtils.getPrice(null, currency).split(",00")[1].trim()}</div>
            </div>
          </div>
          <div className="discount">
            <div>{t("Discount")}</div>
            <div className="discount-input">
              <input
                min="0"
                max="100"
                type="number"
                value={discount}
                onChange={(event) => {
                  setDiscount(event.target.value);
                }}
              />
              <div className="controls">
                <button
                  className="increase"
                  onClick={() => setDiscount(discount + 1)}
                >
                  <span className="label">{t("Increase")}</span>
                  <ChevronSVG />
                </button>
                <button
                  className="reduce"
                  onClick={() => {
                    let newD = discount - 1 >= 0 ? discount - 1 : 0;
                    setDiscount(newD);
                  }}
                >
                  <span className="label">{t("Reduce")}</span>
                  <ChevronSVG />
                </button>
              </div>
              <div className="symbol">%</div>
            </div>
          </div>
          <div className="total">
            <div>{t("Total")}</div>
            <div className="discounted-total">
              {PriceUtils.getPrice(cartDiscountedTotal, currency)}
            </div>
          </div>
          <div className="total">
            {user.priceListCode !== 5022 && t('I prezzi sono IVA inclusa')}
          </div>


          <div className="actions">
            <button className="button" onClick={() => handleModalShow()} disabled={!validateEmail()}>
              {t("Save quotation")}
            </button>
            <button
              className="button-alt"
              onClick={() => {
                emptyCart();
                clearCartMetadata();
              }}
            >
              {t("empty quotation")}
            </button>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleModalClose}>
        <h3 className="title">{t("Save quotation")}</h3>
        {genericQuotationError && (<p className="error"> {genericQuotationError} </p>)}
        <p className="description">
          {t("Name the quote, it will be saved in your quote history.")}
        </p>
        
        <label className="label">{t("Quotation name")}</label>
        <input
          type="text"
          value={quotationName}
          placeholder={t("clientname-yyyy-mm-dd")}
          onChange={(event) => {
            setQuotationName(event.target.value);
          }}
        />

        <div className="actions">
          <button className="button-alt" onClick={handleModalClose}>
            {t("Cancel")}
          </button>

          <button className="button" onClick={saveQuotation}>
            {t("Confirm")}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Cart;
