import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavDropdown } from "react-bootstrap";

import { ReactComponent as SearchSVG } from "./../../img/icons/search.svg";
import categories from "../../helpers/categories";
// images imports
import { configuredCategoryImgs, configuredOutletCategoryImgs } from "../../helpers/imgRetrievers";
import ReactGA from "react-ga4";
import useDocumentTitle from "../../helpers/useDocumentTitle";


const Configurati = (props) => {
  const [search, setSearch] = React.useState("");
  const [category, setCategory] = useState("all");
  const [brandCode, setBrandCode] = useState({ key: 'CAL', label: 'CALLIGARIS' });
  const history = useHistory();
  console.log("props", props);
  const productGroup = props.productGroup || "configured";
  console.log("productGroup", productGroup);
  let imgs = configuredOutletCategoryImgs;
  if(productGroup === "configured") {
    imgs = configuredCategoryImgs;
  }
  const { t } = useTranslation();
  useDocumentTitle(productGroup == "configured" ? "Configurati": "Outlet")

  const changePath = (location) => {
    history.push(location);
  };
  const setCategoryPath = (category) => {
    setCategory(category);
    localStorage.removeItem("brandCode");
    localStorage.removeItem("minimumQuantity");
    localStorage.removeItem("textSearch");
    localStorage.removeItem("category");
    changePath(`/${productGroup}/search?category=${category}`);
  };

  return (
    <div className="main configurated">
      <div className="container">
        <div className="page-header">
          <ul className="breadcrumbs">
            <li onClick={() => changePath("/")}>{t("Home")}</li>
            {productGroup === "configured" && <li className="active">{t("Configured")}</li>}
            {productGroup === "outlet" && <li className="active">{t("Outlet")}</li>}
          </ul>
          {productGroup === "configured" && <h1 className="title">{t("Configured")}</h1>}
          {productGroup === "outlet" && <h1 className="title">{t("Outlet")}</h1>}


          <div className="search-form">
            <form onSubmit={(e) => e.preventDefault()} className="form-configured">
              <div className="search-input">
                <input
                  type="text"
                  placeholder={t("Search product") + "..."}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <button
                  onClick={() => {
                    changePath(`/${productGroup}/search?search=${search}&brandCode=${brandCode.key}`)
                    ReactGA.event(`search_${search}`)
                  }}
                  className="only-icon"
                >
                  <span className="label">{t("Search")}</span>
                  <SearchSVG />
                </button>
              </div>

              <div className="option-input">
                <NavDropdown
                  id="category-button"
                  title={t(category)}
                  onSelect={(cat) => setCategoryPath(cat)}
                >
                  {categories['configured'].map((cat) => {
                    return (
                      <NavDropdown.Item
                        eventKey={cat.key}
                        active={category === cat.key}
                        key={cat.key}
                      >
                        {t(cat.value)}
                      </NavDropdown.Item>
                    );
                  })}
                </NavDropdown>
              </div>
              <div className="option-input">
                <NavDropdown
                  id="brand-code-button"
                  title={t(brandCode.label)}
                  onSelect={(brandCode, component) => { setBrandCode({ key: brandCode, label: component.target.text }) }}
                >
                  <NavDropdown.Item
                    eventKey="CAL"
                    active={"CAL" === brandCode.key}
                    key="CAL"
                  >
                    CALLIGARIS
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    eventKey="CNB"
                    active={"CNB" === brandCode.key}
                    key="CNB"
                  >
                    CONNUBIA
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </form>
          </div>
        </div>

        <div className="grid">
          <div className="category-card">
            <div
              className="category-card-wrapper"
              onClick={() => setCategoryPath("sedie")}
            >
              <img
                src={imgs.sedie}
                alt=""
              />
              <div className="overlay"></div>
              <div className="content">
                <h2 className="title">{t("sedie")}</h2>
              </div>
            </div>
          </div>

          <div className="category-card">
            <div
              className="category-card-wrapper"
              onClick={() => setCategoryPath("sgabelli")}
            >
              <img
                src={imgs.sgabelli}
                alt=""
              />
              <div className="overlay"></div>
              <div className="content">
                <h2 className="title">{t("sgabelli")}</h2>
              </div>
            </div>
          </div>
          {productGroup != "outlet" && <div className="category-card">
            <div
              className="category-card-wrapper"
              onClick={() => setCategoryPath("poltrone")}
            >
              <img
                src={imgs.poltrone}
                alt=""
              />
              <div className="overlay"></div>
              <div className="content">
                <h2 className="title">{t("poltrone")}</h2>
              </div>
            </div>
          </div>}

          <div className="category-card">
            <div
              className="category-card-wrapper"
              onClick={() => setCategoryPath("tavoli")}
            >
              <img
                src={imgs.tavoli}
                alt=""
              />
              <div className="overlay"></div>
              <div className="content">
                <h2 className="title">{t("tavoli")}</h2>
              </div>
            </div>
          </div>

          <div className="category-card">
            <div
              className="category-card-wrapper"
              onClick={() => setCategoryPath("madie_mobili")}
            >
              <img
                src={imgs.mobili_giorno}
                alt=""
              />
              <div className="overlay"></div>
              <div className="content">
                <h2 className="title">{t("madie_mobili")}</h2>
              </div>
            </div>
          </div>

          <div className="category-card">
            <div
              className="category-card-wrapper"
              onClick={() => setCategoryPath("oggettistica")}
            >
              <img
                src={imgs.oggettistica}
                alt=""
              />
              <div className="overlay"></div>
              <div className="content">
                <h2 className="title">{t("oggettistica")}</h2>
              </div>
            </div>
          </div>

          <div className="category-card">
            <div
              className="category-card-wrapper"
              onClick={() => setCategoryPath("complementi")}
            >
              <img
                src={imgs.complementi}
                alt=""
              />
              <div className="overlay"></div>
              <div className="content">
                <h2 className="title">{t("complementi")}</h2>
              </div>
            </div>
          </div>

          <div className="category-card">
            <div
              className="category-card-wrapper"
              onClick={() => setCategoryPath("tappeti")}
            >
              <img
                src={imgs.tappeti}
                alt=""
              />
              <div className="overlay"></div>
              <div className="content">
                <h2 className="title">{t("tappeti")}</h2>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default Configurati;
