import React, { useState } from "react";
import { useCart } from "react-use-cart";
import ReactGA from 'react-ga4'
import PriceUtils from "../../helpers/price";
import { Link, useHistory, useLocation } from "react-router-dom";
import ApiService from "../../actions/apiService";
import { useTranslation } from "react-i18next";

import { ReactComponent as ArrowSVG } from "./../../img/icons/arrow.svg";
import { ReactComponent as MinusSVG } from "./../../img/icons/minus.svg";
import { ReactComponent as MtsMtoSVG } from "./../../img/icons/mts-mto.svg";
import { ReactComponent as PcSVG } from "./../../img/icons/pc.svg";
import { ReactComponent as PlusSVG } from "./../../img/icons/plus.svg";
import { useAlert } from 'react-alert'
import placeholder from "./../../img/placeholder.jpg";
import { retrieveMTOImages } from "../../helpers/imgRetrievers";
import { trimPDFPaths, generalConditionsOfSale } from '../../helpers/documents.js';
import { Spinner } from "react-bootstrap";
import deliveryTime from "../../helpers/products";
import useDocumentTitle from "../../helpers/useDocumentTitle";

const ProductDetail = () => {
  const alert = useAlert();
  const { i18n, t } = useTranslation();
  const { addItem, items, updateItemQuantity } = useCart();
  const history = useHistory();
  const location = useLocation();
  const goToSearch = function () {
    history.goBack();
  };
  const changePath = (location) => {
    history.push(location);
  };
  const item = JSON.parse(localStorage.getItem("item"));
  const product = JSON.parse(localStorage.getItem("product"));
  const productType = localStorage.getItem("productType");
  const user = JSON.parse(sessionStorage.getItem('user'));
  const category = localStorage.getItem("category");
  let itemAlreadyOnCart = items.findIndex((itm) => itm.id === product.id) >= 0;
  //const productDetail = await ApiService.get('/products/', query)
  useDocumentTitle(`Dettaglio prodotto${item.codice ? " - " + item.codice : ''}`)

  const quantityPerPackage = product.quantityPerPackage || 1;
  const minimumBatch = product.minimumBatch || 1;
  const [quantity, setQuantity] = React.useState(product.minimumBatch);
  const [hasEmptyImg, sethasEmptyImg] = React.useState(false);
  const [generalInfo, setGeneralInfo] = React.useState();

  async function addToCart() {
    if (!validateItemCurrency()) {
      alert.removeAll();
      alert.show(t('Product cannot be added to the quotation'), { timeout: 2500, type: 'error' });

      return;
    }

    item.quantityPerPackage = quantityPerPackage;
    item.minimumBatch = minimumBatch;
    item.product = initCartItemProduct();
    if (!itemAlreadyOnCart) {
      await addItem(item, quantity);
      itemAlreadyOnCart = true;
    } else {
      const oldQuantity = items.find((itm) => itm.id === product.id).quantity;
      item.quantity = oldQuantity + quantity;
      updateItemQuantity(item.id, oldQuantity + quantity);
    }
    alert.removeAll();
    window.setTimeout(function () {
      alert.show(t('Product added to the quotation'));
    }, 250);

    document.querySelector(".header").classList.remove("hidden"); // TMP
  }

  let procurementMethod = "";
  if (product.procurementMethod === "MTS") {
    procurementMethod = "Made to stock";
  } else if (product.procurementMethod === "MTO") {
    procurementMethod = "Made to order";
  }

  const validateItemCurrency = () => {
    if (items.length === 0)
      return true;

    return items.filter(i => i.currency === item.currency).length === items.length;
  }

  const initCartItemProduct = () => {
    let cartItemProduct = { procurementMethod: product.procurementMethod || '' }
    cartItemProduct.parentVersion = product.parentVersion;
    return cartItemProduct;
  }

  React.useEffect(() => {
    /* window.gtag('event', `product_${product.productCode}_details_retrieved`, {
      'event_callback': () => {
        console.log(`product ${product.productCode} details retrieved event sent to google analytics`)
      }
    }); */
    ReactGA.event(`product_${product.productCode}_details_retrieved`)
    //ReactGA.event({category:"product", ,label})
  }, []);

  const [currentSlide, setCurrentSlide] = useState(0);
  const length = 2;

  const nextSlide = () => {
    setCurrentSlide(currentSlide === length - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? length - 1 : currentSlide - 1);
  };

  const goToSlide = (i) => {
    const clamp = Math.max(0, Math.min(i, length - 1));

    if (clamp !== currentSlide) {
      setCurrentSlide(clamp);
    }
  };

  const retrieveGeneralInfo = async () => {
    if (product.procurementMethod === 'MTS' || productType === 'outlet') {
      const res = await ApiService.get(`/products/availability/${product.productCode}`, {productGroup:productType});
      setGeneralInfo({
        'product kit': product.productCode,
        'quantity': res.Quantita,
        'quickShip': res.quickShip ? "yes" : "no",
        'preparation method': res.MetodoApprontamento,
        'delivery time': deliveryTime[res.MetodoApprontamento]
      })
    }
    else {
      setGeneralInfo({
        msg: "Made to order: usually shipped in 6-8 weeks."
      })
    }
  }


  React.useEffect(() => {
    retrieveGeneralInfo().catch(
      _ => {
        setGeneralInfo({
          msg: "Sorry, the availability information for this product could not be retrieved."
        })
      }
    )
  }, [])

  const renderGeneralInfo = () => {
    if (generalInfo && generalInfo.msg) {
      return (
        <>
          <div style={{ 'fontWeight': 'bold' }}>
            {t(generalInfo.msg)}
          </div>
        </>
      )
    }
    return (
      <>
        {Object.keys(generalInfo)
            .filter(key => key !="quickShip" || user.priceListCodes.includes(5022) )
            .map(key => {
          return (
            <div className={"info-section " + (key == "delivery time" ? "deliveryTime-section" : "")} key={key}>
              <span className="title">{t(key)}</span>
              <span>{t(generalInfo[key])}</span>
              {key == "delivery time" && <span> {t("The preparation and shipping times will be confirmed at the time of placing the order.")}</span>}
            </div>
          )
        })}
      </>
    )
  }

  return (
    <div className="main product-detail">
      <div className="container">
        <div className="phone-container">
          <div className="page-header">
            <div className="left">
              <ul className="breadcrumbs">
                <li onClick={() => changePath("/")}>{t("Home")}</li>
                {productType === 'configured' && <li onClick={() => changePath("/configured")}>
                  {t("Configured")}
                </li> }
                {productType === 'outlet' && <li onClick={() => changePath("/outlet")}>
                  {t("Outlet")}
                </li> }
                <li
                  onClick={() =>
                    changePath(`/${productType}/search?category=${category}`)
                  }
                >
                  {t(category)}
                </li>
                <li className="active text-uppercase">{item.codice}</li>
              </ul>
              <h1 className="title">{item.title}</h1>
              <p className="subtitle">{item.description1}</p>
            </div>

            <div className="right">
              <div className="procurement-method">
                <div className="icon">
                  <MtsMtoSVG />
                </div>
                <div>{procurementMethod}</div>
              </div>
            </div>
          </div>

          <div className="images slideshow">
            <div className={`left slide ${currentSlide === 0 ? "active" : ""}`}>
              {product.procurementMethod !== 'MTO'
                ? <img src={item.img} alt={item.title} onError={(currentTarget) => {
                  currentTarget.target.src = placeholder;
                }} />
                : retrieveMTOImages({ ...item, product })}
            </div>
            <div
              className={`right slide ${currentSlide === 1 ? "active" : ""} ${hasEmptyImg ? '' : 'rendered'}`}
            >
              {!hasEmptyImg && <img src={`https://www.calligaris.com/media-prods/listini-digitali-icone/${encodeURIComponent(product.parentVersion)}_icon.jpg`} onError={() => {
                sethasEmptyImg(true)
              }} alt={t("Icona")} />}
              <div className="info">
                <div className="size">{product.dimensions}</div>
                <span className="more-info">
                  <span className="label">{t("More info")}</span>
                  <ArrowSVG />
                </span>
              </div>
            </div>
          </div>
          {!hasEmptyImg && <div className="slideshow-pagination">
            <button
              className={currentSlide === 0 ? "active" : ""}
              onClick={() => goToSlide(0)}
            >
              <span className="label">1</span>
              <span className="bullet"></span>
            </button>
            <button
              className={currentSlide === 1 ? "active" : ""}
              onClick={() => goToSlide(1)}
            >
              <span className="label">2</span>
              <span className="bullet"></span>
            </button>
          </div>}

          <div className="add-to-cart">
            <div className="left">
              <div className="price">
                {PriceUtils.getPrice(item.price, item.currency)}
              </div>
              <div className="pieces">
                <div>
                  <PcSVG />
                  <span>
                    {quantityPerPackage}&nbsp;{t("pc")}
                  </span>
                </div>
              </div>
            </div>
            {/*<div className="right">*/}
            {/*  <div className="quantity">*/}
            {/*    <button*/}
            {/*      disabled={quantity === minimumBatch}*/}
            {/*      onClick={() => {*/}
            {/*        let newQ = quantity - quantityPerPackage;*/}
            {/*        newQ = newQ >= minimumBatch ? newQ : minimumBatch;*/}
            {/*        setQuantity(newQ);*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <span className="label">{t("Reduce")}</span>*/}
            {/*      <MinusSVG />*/}
            {/*    </button>*/}
            {/*    <span>{quantity}</span>*/}
            {/*    <button*/}
            {/*      onClick={() => setQuantity(quantity + quantityPerPackage)}*/}
            {/*    >*/}
            {/*      <span className="label">{t("Add")}</span>*/}
            {/*      <PlusSVG />*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*  <button className="button" onClick={addToCart}>*/}
            {/*    {t("Add")}*/}
            {/*  </button>*/}
            {/*</div>*/}
          </div>
          <div className="general-info">
            {!generalInfo
              ? <div className="loader">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">{t("Loading")}...</span>
                </Spinner>
              </div>
              : renderGeneralInfo()
            }
          </div>
          <span></span>
        </div>

        <h2 className="description-title">{product.productDescription3}</h2>

        <div className="description description1">
          <div className="left">
            <div>{product.description}</div>
            {product.procurementMethod === 'MTO' &&
              <div style={{ 'paddingTop': '10px' }}>
                {t("For smaller quantities, price increases are applied. Call customer service for more information")}
              </div>
            }

          </div>
          <div className="right">
            <table>
              <tbody>
                <tr>
                  <th>{t("Material")}</th>
                  <td>{[...new Set(product.partTypes.map((partType) => {
                    return partType?.material?.description1
                  }))].join(', ')}</td>
                </tr>
                <tr>
                  <th>{t("Finishings")}</th>
                  <td>{[...new Set(product.partTypes.map((partType) => {
                    return partType?.trim?.description1
                  }))].join(', ')}</td>
                </tr>
                <tr>
                  <th>{t("Dimensions")}</th>
                  <td>{product.sizes}</td>
                </tr>
                <tr>
                  <th>{t("FireProof")}</th>
                  <td>{t(product.fireProof ? 'yes' : 'no')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="description description2">
          <div className="left">
            <h2>{t("Details")}</h2>
            <div style={{ marginBottom: '10px' }}
              dangerouslySetInnerHTML={{ __html: product.technicalDetails }}
            />
            {product.procurementMethod === 'MTO' &&
              <Link className="link"
                onClick={_ => { ReactGA.event(`download_trim`) }}
                to={trimPDFPaths[i18n.language.split('-')[0]]} target="_blank" download>
                {t("Download trims")}
              </Link>}
          </div>
          <div className="right">
            <h2>{t("Packing")}</h2>
            <div>
              <table>
                <tbody>
                  <tr>
                    <th>{t("Weight")}</th>
                    <td>{product.weight} {product.weightUnit === 'KILOGRAM' ? 'Kg': 'lbs'}</td>
                  </tr>
                  <tr>
                    <th>{t("Volume")}</th>
                    <td>{product.volume} {product.volumeUnit === 'CUBIC_METER' ? 'm³': 'ft³'}</td>
                  </tr>
                  <tr>
                    <th>{t("Packages")}</th>
                    <td>{product.numberOfPackages}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
