import {ReactComponent as SarchSVG} from "../../img/icons/search.svg";
import {ClearButton, Typeahead} from "react-bootstrap-typeahead";
import React from "react";
import {useTranslation} from "react-i18next";

const FilterSelect = (props)=>{
    const { t } = useTranslation();
    return(
<div>
    <div className="label">{props.label}</div>
    <div className="input-wrapper">
        <div className="icon">
            <SarchSVG />
        </div>
        <Typeahead
            id="basic-typeahead-single"
            labelKey="description1"
            multiple={props.multiple}
            onChange={props.setSelected}
            options={props.options}
            placeholder={props.placeholder}
            selected={props.selected}
            renderMenuItemChildren={props.renderMenuItemChildren}
            emptyLabel={t('No matches found')}
            >

            {({ onClear, selected }) => (
                <div className="rbt-aux">
                    {!props.multiple && !!selected.length && <ClearButton onClick={onClear} />}
                </div>
            )}
        </Typeahead>
    </div>
</div>);
}

export default FilterSelect;