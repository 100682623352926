import React from "react";
import ReactDOM from "react-dom";
//import "./index.css";
import "./style/index.scss";
//import "bootstrap/dist/css/bootstrap.css";
import { Provider } from "react-redux";
import store, { history } from "./store";
import reportWebVitals from "./reportWebVitals";
import Layout from "./pages/index";
import { Router } from "react-router-dom";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import ReactGA from "react-ga4";
//import AlertTemplate from "react-alert-template-basic";

import i18n, { t } from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import {Loader} from "react-bootstrap-typeahead";
import { sanitizeNavigatorLanguage } from "./helpers/language";


const AlertTemplate = ({ style, options, message, close }) => (
  <div style={style} className="alert-banner">
    <div className="message">{message}</div>
    <button onClick={close} className="close">
      <span className="label">{t("Close")}</span>
    </button>
  </div>
);
let lng =  (localStorage.getItem("language") && localStorage.getItem("language")!== 'null') ? localStorage.getItem("language") : navigator.language;
i18n
  .use(Backend)
  .use(initReactI18next)

  .init({
      fallbackLng: 'EN_US',
      debug: true,
      react: {
          wait: true
      },
      allowMultiLoading: true,
      backend: {
          loadPath:  '/locales/{{lng}}.json',
          crossDomain: false
      },
      lng: sanitizeNavigatorLanguage(lng), // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 3000,
  offset: "100px",
  // you can also just use 'scale'
  transition: transitions.FADE,
};


fetch("/config.json")
  .then(data => {
    data.json().then(config => {
      console.log(window.location.hostname)
      if (window.location.hostname.startsWith('zico')) {
        ReactGA.initialize(config.gtag["zico.calligaris.com"]);
      } else {
        ReactGA.initialize(config.gtag["staging-zico.calligaris.com"]);
      }
    }).catch(err => console.log(err))
  })


ReactDOM.render(
  <React.StrictMode>
      <React.Suspense fallback={<Loader/>}>
          <AlertProvider template={AlertTemplate} {...options}>
              <Provider store={store}>
                  <Router history={history}>
                      <Layout />
                  </Router>
              </Provider>
          </AlertProvider>
      </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
