import React from "react";
import FilterByPart from "./FilterByPart";
import MultiRangeSlider from "../MultiRangeSlider/MultiRangeSlider";
import { useTranslation } from "react-i18next";
const FiltersComposer = (props) => {
  const { t } = useTranslation();
  console.log("FiltersComposer", props.category, props.productType);
  let category =
    props.category +
    (props.productType === "configurable" ? "_configurable" : "");
  let filter = props.filters;
  const isMTOSetter = props.isMTOSetter;
  const quickShipSetter = props.quickShipSetter;
  const user = JSON.parse(sessionStorage.getItem('user'));
  const sizeUnit = localStorage.getItem('sizeUnit') || 'decimal';
  let maxMultiRangeSlider = 400;
  if( sizeUnit != 'decimal'){
    maxMultiRangeSlider = 160;
  }

  let setterFilter = function (flt, index = 1) {
    console.log("set filter filterComposer", flt, index);
    let newFilter = [];
    newFilter[0] = filter[0];
    newFilter[1] = filter[1];
    newFilter[2] = filter[2];
    newFilter[3] = filter[3];
    newFilter[index - 1] = flt;
    props.filtersSetter(newFilter);
  };

  let setterFilter1 = function (flt) {
    setterFilter(flt, 1);
  };

  let setterFilter2 = function (flt) {
    setterFilter(flt, 2);
  };

  let setterFilter3 = function (flt) {
    setterFilter(flt, 3);
  };

  let setterFilter4 = function (flt) {
    setterFilter(flt, 4);
  };

  const MTOCheckbox = () => {
    return (
      <>
        <div className="MTO-checkbox">
          <input type="checkbox" onChange={(e) => {
            isMTOSetter(e.target.checked);
          }} />{t("made to order")}
        </div>
      </>
    )
  }

  const QuickShipCheckbox = () => {
    return (user.priceListCodes.includes(5022) &&
      <>
        <div className="MTO-checkbox">
          <input type="checkbox" onChange={(e) => {
            quickShipSetter(e.target.checked);
          }} />{t("quickShip")}
        </div>
      </>
    )
  }


  return (
    <>
      {
        {
          sedie: (
            <>
              <h5>{t("Structure")}</h5>

              <div className="filters-row">
                <FilterByPart
                  className="mb-5"
                  category={props.category}
                  productType={props.productType}
                  part="struttura"
                  setFilter={setterFilter1}
                  filter={props.filters[0]}
                />
              </div>

              <h5>{t("Seat")}</h5>

              <div className="filters-row">
                <FilterByPart
                  className="mb-5"
                  category={props.category}
                  productType={props.productType}
                  part="sedile"
                  setFilter={setterFilter2}
                  filter={props.filters[1]}
                />
                {MTOCheckbox()}
                {QuickShipCheckbox()}
              </div>
            </>
          ),
          poltrone: (
            <div className="filters-row">
              <FilterByPart
                category={props.category}
                productType={props.productType}
                part={null}
                setFilter={setterFilter1}
                filter={props.filters[0]}
              />
              {MTOCheckbox()}
              {QuickShipCheckbox()}
            </div>
          ),
          sgabelli: (
            <>
              <h5>{t("Structure")}</h5>

              <div className="filters-row">
                <FilterByPart
                  className="mb-5"
                  category={props.category}
                  productType={props.productType}
                  part="struttura"
                  setFilter={setterFilter1}
                  filter={props.filters[0]}
                />
              </div>

              <h5>{t("Seat")}</h5>

              <div className="filters-row">
                <FilterByPart
                  className="mb-5"
                  category={props.category}
                  productType={props.productType}
                  part="sedile"
                  setFilter={setterFilter2}
                  filter={props.filters[1]}
                />
                {MTOCheckbox()}
                {QuickShipCheckbox()}
              </div>
            </>
          ),
          tavoli: (
            <>
              <h5>{t("Legs and Frame")}</h5>
              <div className="filters-row">
                <FilterByPart
                  className="mb-5"
                  category={props.category}
                  productType={props.productType}
                  part="gambe_cassonetto"
                  setFilter={setterFilter2}
                  filter={props.filters[1]}
                />
              </div>

              <h5>{t("Top and extension")}</h5>
              <div className="filters-row">
                <FilterByPart
                  className="mb-5"
                  category={props.category}
                  productType={props.productType}
                  part="piano_allunga"
                  setFilter={setterFilter3}
                  filter={props.filters[2]}
                />
              </div>
              <h5>{t("Base")}</h5>
              <div className="filters-row">
                <FilterByPart
                  className="mb-5"
                  category={props.category}
                  productType={props.productType}
                  part="basamento"
                  setFilter={setterFilter1}
                  filter={props.filters[0]}
                />
                {MTOCheckbox()}
                {QuickShipCheckbox()}
              </div>
              <h5>{t("Width")}&nbsp;({sizeUnit == 'decimal'? t('cm'): t('inches')})</h5>
              <div className="filters-row">
                <MultiRangeSlider
                  min={0}
                  max={maxMultiRangeSlider}
                  onChange={({ min, max }) => props.lengthSetter(min, max)}
                />
              </div>

              <h5>{t("Depth")}&nbsp;({sizeUnit == 'decimal'? t('cm'): t('inches')})</h5>
              <div className="filters-row">
                <MultiRangeSlider
                  min={0}
                  max={maxMultiRangeSlider}
                  onChange={({ min, max }) => props.depthSetter(min, max)}
                />
              </div>
            </>
          ),
          madie_mobili: (
            <>
              <h5>{t("Structure")}</h5>
              <div className="filters-row">
                <FilterByPart
                  className="mb-5"
                  category={props.category}
                  productType={props.productType}
                  part="struttura"
                  setFilter={setterFilter1}
                  filter={props.filters[0]}
                />
              </div>

              <h5>{t("Front Drawer")}</h5>
              <div className="filters-row">
                <FilterByPart
                  className="mb-5"
                  category={props.category}
                  productType={props.productType}
                  part="frontale_cassetto"
                  setFilter={setterFilter2}
                  filter={props.filters[1]}
                />
              </div>

              <h5>{t("Door/Drawer")}</h5>
              <div className="filters-row">
                <FilterByPart
                  className="mb-5"
                  category={props.category}
                  productType={props.productType}
                  part="anta_cassetto"
                  setFilter={setterFilter3}
                  filter={props.filters[2]}
                />
              </div>

              <h5>{t("Top")}</h5>
              <div className="filters-row">
                <FilterByPart
                  className="mb-5"
                  category={props.category}
                  productType={props.productType}
                  part="top"
                  setFilter={setterFilter4}
                  filter={props.filters[3]}
                />
                {MTOCheckbox()}
                {QuickShipCheckbox()}
              </div>
            </>
          ),
          oggettistica: (
            <>
              <div className="filters-row">
                <FilterByPart
                  className="mb-5"
                  category={props.category}
                  productType={props.productType}
                  part={null}
                  setFilter={setterFilter1}
                  filter={props.filters[0]}
                />
                {MTOCheckbox()}
                {QuickShipCheckbox()}
              </div>
            </>
          ),
          complementi: (
            <>
              <h5>{t("Top and extension")}</h5>
              <div className="filters-row">
                <FilterByPart
                  className="mb-5"
                  category={props.category}
                  productType={props.productType}
                  part="piano_allunga"
                  setFilter={setterFilter1}
                  filter={props.filters[0]}
                />
              </div>

              <h5>{t("Structure")}</h5>
              <div className="filters-row">
                <FilterByPart
                  className="mb-5"
                  category={props.category}
                  productType={props.productType}
                  part="struttura"
                  setFilter={setterFilter2}
                  filter={props.filters[1]}
                />
                {MTOCheckbox()}
                {QuickShipCheckbox()}
              </div>
            </>
          ),
          tappeti: (
            <>
              <h5>{t("Width")}&nbsp;({sizeUnit == 'decimal'? t('cm'): t('inches')})</h5>
              <div className="filters-row">
                <MultiRangeSlider
                  min={0}
                  max={maxMultiRangeSlider}
                  onChange={({ min, max }) => props.lengthSetter(min, max)}
                />
              </div>

              <h5>{t("Depth")}&nbsp;({sizeUnit == 'decimal'? t('cm'): t('inches')})</h5>
              <div className="filters-row">
                <MultiRangeSlider
                  min={0}
                  max={maxMultiRangeSlider}
                  onChange={({ min, max }) => props.depthSetter(min, max)}
                />
              </div>

              <h5>{t("Material")}</h5>
              <div className="filters-row">
                <FilterByPart
                  className="mb-5"
                  category={props.category}
                  productType={props.productType}
                  part={null}
                  setFilter={setterFilter2}
                  filter={props.filters[1]}
                />
                {MTOCheckbox()}
                {QuickShipCheckbox()}
              </div>
            </>
          ),
          // 'letti': <><h5>Larghezza:</h5><FilterByPart category={props.category} part="PIA" setFilter={setterFilter1} filter={props.filters[1]}/>
          //     <h5>Profondita':</h5><FilterByPart category={props.category} part="PIA" setFilter={setterFilter1} filter={props.filters[1]}/>
          //     <h5>Materiale:</h5><FilterByPart category={props.category} part={null} setFilter={setterFilter2} filter={props.filters[2]}/></>,
          // 'divani': <><h5>Larghezza:</h5><MultiRangeSlider min={0} maxMultiRangeSlider={1000} onChange={({ min, maxMultiRangeSlider }) => props.setLength(min,maxMultiRangeSlider)}/>
          //     <h5>Profondita':</h5><MultiRangeSlider min={0} maxMultiRangeSlider={1000} onChange={({ min, maxMultiRangeSlider }) => props.setDepth(min,maxMultiRangeSlider)}/>
          //     <h5>Materiale:</h5><FilterByPart category={props.category} part={null} setFilter={setterFilter2} filter={props.filters[2]}/></>,
          //'bar': <Bar />
          letti_configurable: (
            <div className="filters-row">
              <FilterByPart
                category={category}
                part={null}
                setFilter={setterFilter1}
                filter={props.filters[0]}
              />
            </div>
          ),
          divani_configurable: (
            <div className="filters-row">
              <FilterByPart
                category={category}
                part={null}
                setFilter={setterFilter1}
                filter={props.filters[0]}
              />
            </div>
          ),
          //'bar': <Bar />
        }[category]
      }
    </>
  );
};

export default FiltersComposer;
