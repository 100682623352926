import request from '../request.js';
import store from "../store";

const ApiService = {};

let cache = {};
let username = '';
let cacheEnabled = true;

ApiService.getFile = async (resource, query = {}) => {
    const res = await  request(`${resource}`, {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'GET',
        params: query
    });
    return res.blob();
};

function callbackError(response) {
        console.error(`error request: '${response.status}: ${response.json()}`);
        if(response.status === 401){
            store.dispatch({
                type: 'CLEAR_TOKEN'
            });
        }
        throw response.json();
    }

ApiService.get = async (resource, query = {}) => {
    let sizeUnit = localStorage.getItem("sizeUnit") || "decimal";
    let key = `${resource}-${sizeUnit}-${JSON.stringify(query)}`;
    if (!cacheEnabled || (!cache[key] || key.startsWith('/quotations') || key.startsWith('/profile') || key.startsWith('/products/availability'))) {
        cache[key] = request(`${resource}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'GET',
            params: query
        });
    }
    const responseBody = await cache[key];
    if (!responseBody.ok) {
        callbackError(responseBody);
    }
    if(!responseBody.json_text) {
        responseBody.json_text = await responseBody.json();
    }

    //if the resource start with '/profile' check the username of the response and if it is different empty the cache
    if(resource.startsWith('/profile')){
        if(username != responseBody.json_text.username){
            cache = {};
            username = responseBody.json_text.username;
        }

    }
    return responseBody.json_text;
};

ApiService.post = async (resource, data = {}) => {
    const response = await request(`${resource}`,
        {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(data)
        });

    if (!response.ok) {
        callbackError(response)
    }

    return await response.json()
};
ApiService.put = async (resource, data = {}) => {
    const response = await request(`${resource}`,
        {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify(data)
        });

    if (!response.ok) {
        callbackError(response)
    }

    return true;
};

ApiService.delete = async (resource) => {
    const response = await request(`${resource}`,
        {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'DELETE'
        });

    if (!response.ok) {
        callbackError(response)
    }

    return true;
};
export default ApiService;