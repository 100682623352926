export default class PriceUtils {

    static getPrice(number, currency) {
        let languageStorage = (localStorage.getItem("language") && localStorage.getItem("language")!== 'null') ? localStorage.getItem("language") : "it_it";
        let language = languageStorage.split("_")[0].toLowerCase() + "-" +
            languageStorage.split("_")[0].toUpperCase();
        return new Intl.NumberFormat(language, {
            style: 'currency', currency: currency, currencyDisplay: 'narrowSymbol',
        }).format(number);
    }
}