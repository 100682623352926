import React, { useState } from "react";
import { login, getUser } from "../../actions/user.js";
import { Link, useHistory } from "react-router-dom";
import ReactGA from "react-ga4";

import { useTranslation } from "react-i18next";
import { ReactComponent as PictogramSVG } from "./../../img/icons/pictogram.svg";
import backgroundImage from "./../../img/login-background_img.jpg"
import useDocumentTitle from "../../helpers/useDocumentTitle.js";

function LoginForm() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({ username: "", password: "" });
  const history = useHistory();
  useDocumentTitle("Login")
  const { t } = useTranslation();
  const loginFunction = async () => {
    try {
      setError("");
      setLoading(true);
      const response = await login(details.username, details.password);
      const userJson = await getUser();
      setLoading(false);
      console.log({ response });
      history.push("/");
      /* window.gtag('event', 'signin_process_complete', {
        'event_callback': () => {
          console.log("sign in event sent to google analytics")
        }
      }); */
      ReactGA.event('signin_process_complete')
    } catch (err) {
      err.statusCode === 401
        ? setError("Credentials not valid")
        : setError("The service is not available at the moment. Please, try again later.");
      console.log(err);
      setLoading(false);
    }
  };

  console.log("LoginForm");
  return (
    <>
      <div
        className="page-background"
        style={{
          backgroundImage: `url(${backgroundImage})`
        }}
      >
      </div>
      <div className="header transparent">
        <div className="container">
          <div className="header-wrapper">
            <div className="logo">calligaris</div>
            <div className="pictogram">
              <PictogramSVG />
            </div>
          </div>
        </div>
      </div>

      <div className="main login">
        <div className="container">
          <div className="form-card">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                loginFunction();
              }}
            >
              <h1 className="form-title">{t("Product catalog") + " " + new Date().getFullYear()}</h1>

              <div className="form-row">
                <label htmlFor="text">{t("Username")}</label>
                <input
                  type="text"
                  name="text"
                  placeholder={t("Username")}
                  id="text"
                  onChange={(e) =>
                    setDetails({ ...details, username: e.target.value })
                  }
                  value={details.username}
                />
              </div>

              <div className="form-row">
                <label htmlFor="password">{t("Password")}</label>
                <input
                  type="password"
                  name="password"
                  placeholder={t("Password")}
                  id="password"
                  onChange={(e) =>
                    setDetails({ ...details, password: e.target.value })
                  }
                  value={details.password}
                />
              </div>

              <div className="form-actions">
                <Link to="forgot_pwd">{t("Forgot password?")}</Link>

                <button className="button" type="submit" disabled={loading}>
                  {t("Enter")}
                </button>
              </div>

              {error !== "" && (
                <div className="form-alert danger">{t(error)}</div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginForm;
