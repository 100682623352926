import store from "./store.js";
let config;
export default async function request(url, options) {
  if(!config){
    config = await fetch("/config.json");
    config = await config.json();
  }
  const target = config.be_url || document.location.origin + "/api";
  // let state = store.getState();
  let token = sessionStorage.getItem("accessToken");
  let language = (localStorage.getItem("language") && localStorage.getItem("language")!== 'null') ? localStorage.getItem("language") : "it_it";
  let sizeUnit = localStorage.getItem("sizeUnit") || "decimal";
  url = new URL(target + url);
  //url = new URL(`https://apilistinodigitale.ennova-research.com` + url);
  if (!options.headers) options.headers = {};
  options.headers["Accept"] = "application/json";
  options.headers["Accept-Language"] = language.replace("_","-").toUpperCase();
  options.headers["X-SizeUnit"] = sizeUnit;
  if (options.params) {
    url.search = new URLSearchParams(options.params).toString();
  }
  if (token) {
    options.headers["Authorization"] = `Bearer ${token}`;
  }

  return fetch(url, options);
}
