import { ProductTableDetails } from "./ProductTableDetails";
import { ProductTableImage } from "./ProductTableImage";


export const ProductTableInfo = ({ imgComponent, product }) => {
  return <td className="info">
    <div className="image-details">
      <ProductTableImage >
        {imgComponent}
      </ProductTableImage>
      <ProductTableDetails
        productDescription2={product.productDescription2}
        productDescription3={product.productDescription3}
        description={product.description}
        parentVersion={product.parentVersion}
        title={product.parentDescription}
        product={product.product}
        options={product.options}
      ></ProductTableDetails>
    </div>
  </td>
}