import React from "react";
import { useCart } from "react-use-cart";
import ArticoliCard from "../articoliCard/ArticoliCard";

function showProducts(products, category) {
  return products.map((item) => {
    return <ArticoliCard key={item.codice} item={item} category={category} />;
  });
}

const ProductsResult = (props) => {
  const { addItem } = useCart();
  return (
    <div className="grid">{showProducts(props.products, props.category)}</div>
  );
};

export default ProductsResult;
