import React from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import AuthRoute from "../components/authRoute.jsx";

import Home from "./Home/index";
import Cart from "./Cart/index";
import Quotations from "./Quotations/index";
import Login from "./Login/LoginForm";
import ForgotPwd from "./ForgotPwd/ForgotPwd";
import { checkLogin } from "../actions/user.js";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { CartProvider } from "react-use-cart";
import Configurati from "../components/Configurati/configurati";
import Configurabili from "../components/Configurabili/configurabili";
import ProductSearch from "../components/ProductSearch/ProductSearch";
import ProductDetail from "../components/ProductDetail/ProductDetail";
import ResetPwd from "./ResetPwd/ResetPwd";
import QuotationDetail from "../components/QuotationDetail/QuotationDetail";
import DiTreProductDetail from "../components/ProductDetail/DiTreProductDetail";
import ScrollToTop from "../helpers/scrollToTop";
import { Dashboard } from "./Dashboard/Dashboard.jsx";


class LayoutPage extends React.Component {
  async componentDidMount() {
    console.log("checkLogin before");
    await checkLogin();
    console.log("checkLogin after");
  }

  render() {
    console.log("LayoutPage", this.props.path);
    return (
      <div className="page">
        <CartProvider>
          <ScrollToTop />
          {(this.props.loaded && this.props.user && this.props.user.claims && this.props.user.claims.username
              && <Navbar user={this.props.user.claims}/>
          )}
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgot_pwd" component={ForgotPwd} />
            <Route exact path="/reset-pwd" component={ResetPwd} />
            {(this.props.loaded && this.props.user && this.props.user.claims && this.props.user.claims.username &&
                  <>
                    <AuthRoute exact path="/" component={Home} />
                    {this.props.user.claims.role === 'ADMIN' &&
                        <AuthRoute exact path="/dashboard" component={Dashboard} />
                    }
                    {/*<AuthRoute exact path="/cart" component={Cart} />*/}
                    {/*<AuthRoute exact path="/quotations" component={Quotations} />*/}
                    {/*<AuthRoute*/}
                    {/*    exact*/}
                    {/*    path="/quotations/detail"*/}
                    {/*    component={QuotationDetail}*/}
                    {/*/>*/}
                    {/*<AuthRoute exact path="/configured" component={Configurati} />*/}
                    <AuthRoute exact path="/outlet" component={Configurati} productGroup={'outlet'} />
                    <AuthRoute
                        exact
                        path="/outlet/search"
                        productType="outlet"
                        component={ProductSearch}
                    />
                {/*    <AuthRoute*/}
                {/*        exact*/}
                {/*        path="/configured/search"*/}
                {/*        productType="configured"*/}
                {/*        component={ProductSearch}*/}
                {/*    />*/}
                {/*    <AuthRoute exact path="/configurable" component={Configurabili} />*/}
                {/*    <AuthRoute*/}
                {/*        exact*/}
                {/*        path="/configurable/search"*/}
                {/*        productType="configurable"*/}
                {/*pageName='Ricerca prodotto'*/}
                {/*        component={ProductSearch}*/}
                {/*    />*/}
                    <AuthRoute exact path="/product" component={ProductDetail} />
                    {/*<AuthRoute exact path="/ditre/product" component={DiTreProductDetail} />*/}
                    <Route>
                      <Redirect to={"/"}/>
                    </Route>
                  </>
            )}
            {(this.props.loaded && (!this.props.user || !this.props.user.claims || !this.props.user.claims.username)) && (
                <Redirect from="*" to="/login" />
              )}
            {(this.props.loaded && (!this.props.user || !this.props.user.claims || !this.props.user.claims.username))
                && <Redirect push to="/login" />}
          </Switch>
          {(this.props.loaded && this.props.user && this.props.user.claims && this.props.user.claims.username
              && <Footer />
          )}
        </CartProvider>
      </div>
    );
  }
}

export default withRouter(
  connect((state) => {
    return {
      isLoggedIn: state.user.accessToken,
      loaded: state.user.loaded,
      router: state.router,
      user: state.user,
    };
  })(LayoutPage)
);
