import store, {history} from '../store.js';
import request from '../request.js';
import moment from 'moment';
import ApiService from "./apiService";
import ReactGA from "react-ga4";

let refreshTimer = null;

export const getClaims = token => {
    let claims = token.split('.')[1];
    if(claims){
    claims = atob(claims);
    claims = JSON.parse(claims);
    if (claims.iat)
        claims.iat = moment(claims.iat * 1000);
    if (claims.exp)
        claims.exp = moment(claims.exp * 1000);
    return claims;
    }
    return  null;
};

const setToken = (accessToken, claims) => {
    store.dispatch({
        type: 'SET_TOKEN',
        payload: {
            accessToken,
            claims,
        }
    });
    if (refreshTimer)
        clearTimeout(refreshTimer);

    let now = moment();
    console.info('token will refresh after', claims.exp.diff(now, 'seconds'), 'seconds');
    refreshTimer = setTimeout(async () => {
        // let state = store.getState();

        console.info('Logout the user');
        alert("Your session has expired and you will be redirected to the login page.")
        store.dispatch({
            type: 'CLEAR_TOKEN'
        });
        return history.push('/login');
    }, claims.exp.diff(now, 'seconds') * 1000);
};

export const checkLogin = async () => {
    let accessToken = sessionStorage.getItem('accessToken');

    let claims = {};

    if (!accessToken) {
        console.info('no access token and no refresh token');
        store.dispatch({
            type: 'CLEAR_TOKEN'
        });
        return;
        //return history.push('/login');
    }
    if (accessToken)
        claims = getClaims(accessToken);

    let now = moment();
    //is about to expire or doesn't have access token
    if (!claims || claims.exp.isBefore(now)) {
        console.info('token is expired');
        store.dispatch({
            type: 'CLEAR_TOKEN'
        });
        return;
        //return history.push('/login');

    }
    setToken(accessToken, claims);
    console.info('token is active');

    return await getUser();
};

export const login = async (username, password) => {
    const response = await request('/auth/login', {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
            username,
            password
        })
    });
    let json = await response.json();
    if (!response.ok) {
        switch (response.status) {
            case 400:
                throw json
            case 401:
                throw json;
            default:
                console.error('unexpected error', response.status);
                break;
        }
    }

    if (!json.access_token)
        throw json.error;

    setToken(json.access_token, getClaims(json.access_token));
    return { login: true }

};

export const getUser = async () => {
    let user =  await ApiService.get("/profile");
    sessionStorage.setItem('user', JSON.stringify(user));
    ReactGA.set({ userId: `${user.username}` })
    return user;
};

export const sendResetPwdRequest = async (username) => {
    const response = await request('/user/send-reset-pwd', {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
            username
        })
    });
    let json = await response.json();
    if (!response.ok) {

        switch (response.status) {
            case 400:
                throw json
            case 401:
                throw json;
            default:
                console.error('unexpected error', response.status);
                break;
        }
    }
    return true;

};

export const checkResetPwdToken = async (token) => {
    const response = await request('/user/reset-pwd', {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'GET',
        params: {
            token
        }
    });
    return response.ok;


};

export const sendResetPwd = async (token, newPassword, confirmPassword) => {
    const response = await request('/user/reset-pwd', {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
            token, newPassword, confirmPassword
        })
    });
    return response.ok;


};

export const logout = async () => {
    store.dispatch({
        type: 'CLEAR_TOKEN'
    });
    if (refreshTimer)
        clearTimeout(refreshTimer);
};
