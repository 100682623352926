import React from "react";
import PriceUtils from "../../helpers/price";
import { useHistory } from "react-router-dom";
import { scrollToTop } from "../../helpers/scroll";
import { useTranslation } from "react-i18next";
import placeholder from "./../../img/placeholder.jpg";
import { retrieveMTOImages } from "./../../helpers/imgRetrievers"

const ArticoliCard = ({ item, category }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const changePath = (location) => {
    history.push(location);
  };
  let description1 = item.description1 ||  item.product.category.description1 + " " + item.product.productCode;
  const viewDetailProduct = function () {
    scrollToTop();
    if(item.product){
      localStorage.setItem("product", JSON.stringify(item.product));
      delete item.product;
    }
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("category", category);
    localStorage.setItem("productType", item.productType);
    if(item.productType === "configurable"){
      changePath("/ditre/product");
    } else {
      changePath("/product");
    }

  };
  let availabilityClass='text-danger'
  if(item.availability >= 10){
    availabilityClass = 'text-success';
  } else if(item.availability >0){
    availabilityClass = 'text-warning';
  }

  return (
    <div className="product-card" onClick={() => viewDetailProduct()}>
      { item.productType === 'configurable' ||  item.product.procurementMethod !== 'MTO'
        ? <img src={item.img} alt={item.title} onError={(currentTarget) => {
            currentTarget.target.src = placeholder; }} /> 
        : retrieveMTOImages(item)}
      <div className="content">
        <h2 className="title">{item.title}</h2>
        <div className="meta">
          <div className="description1">{description1}</div>
          { (item.productType === 'configured' || item.productType ==='outlet') && <>
            <div className="description2">{item.description2 || item.productCode}</div>
            <div className="code">{item.codice}</div>
          {item.price && item.currency && <div className={"details"}>
            <span>{PriceUtils.getPrice(item.price, item.currency)}</span>
            <span style={{'float': 'right', 'font-weight': 'bold'}} className={`${availabilityClass} bold`}>{t('availability')}: {item.availability}</span>
            </div> }
          </>}
          {item.productType === "configurable" && <a onClick={() => viewDetailProduct()}>{t("Configura")} ></a>}
        </div>
      </div>
    </div>
  );
};

export default ArticoliCard;
