import { useEffect, useState } from "react"
import CountUp from "react-countup"

export const CounterBox = ({ className, title, decimals, total, totalWithCurrency = "", suffix = "" }) => {

  const [currentTotal, setCurrenTotal] = useState(total)
  const [currentSuffix, setCurrentSuffix] = useState(suffix)

  useEffect(() => {
    if (!total && totalWithCurrency.trim().length > 0) {
      const convertedCurrency = totalWithCurrency.at(-1)
      const convertedTotal = Number(
        totalWithCurrency
          .substring(0, totalWithCurrency.length - 2)
          .replace(".", "")
          .replace(",", ".")
      )
      setCurrentSuffix(convertedCurrency)
      setCurrenTotal(convertedTotal)
    } else if (total) {
      setCurrenTotal(total)
    }
  }, [totalWithCurrency, total])


  return <div className={`${className} counter-container`}>
    <p className="counter-label">{title}</p>
    <CountUp className="counter" end={currentTotal}
      duration={2}
      decimal={","}
      decimals={decimals}
      delay={0}
      suffix={currentSuffix}
    >
      {({ countUpRef }) => (
        <div className="counter-number">
          <span ref={countUpRef} />
        </div>
      )}
    </CountUp>
  </div>
}